import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {UserService} from '../../services/user/user.service';
import {User} from '../../classes/user.class';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {Role} from '../../classes/role.class';
import {AuthorisationService} from '../../services/auth/authorisation.service';
import {LocalStorage} from '../../storage.class';
import {AuthenticationService} from '../../services/auth/authentication.service';
import {CustomerArea} from '../../classes/customerarea.class';
import {CordovaService} from '../../cordova.service';
import {CustomerAreaService} from '../../services/consumer-area/customer-area.service';
import {combineLatest} from 'rxjs';
import {Customer} from '../../classes/customer';
import {CodaltComponent} from '../../codalt.component';

@Component({
    selector: 'app-user-edit',
    templateUrl: './user-edit.component.html',
    styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent extends CodaltComponent implements OnInit {

    saving = false;
    user: User;
    form: FormGroup;
    roles: Role[];
    moreRoles = false;
    AuthorisationService = AuthorisationService;
    LocalStorage = LocalStorage;
    showCustomerAreaSelection = false;
    customerAreas: CustomerArea[];
    customers: Customer[];
    filteredCustomerAreas: CustomerArea[];

    constructor(private userService: UserService,
                private authorisationService: AuthorisationService,
                private authenticationService: AuthenticationService,
                private route: ActivatedRoute,
                private confirmDialogService: ConfirmDialogService,
                private cordovaService: CordovaService,
                private customerAreaService: CustomerAreaService,
                private cdref: ChangeDetectorRef,
                private router: Router) {
        super();
    }

    ngOnInit() {
        this.cordovaService.setBackbuttonAction(() => {
            this.router.navigate(['/users']);
        });
        this.authorisationService.getRoles().subscribe(roles => this.roles = roles);
        this.route.params.subscribe(params => {
            const userId = +params['userId'];
            if (userId) {
                this.getUser(userId);
            } else {
                this.user = new User();
                if (!this.customerAreas) {
                    this.customerAreaService.getList().then(customerAreas => {
                        this.customerAreas = customerAreas;
                        this.user.roles = [];
                        this.setFormGroup(this.user);
                    });
                }

            }
        });
        this.subscriptions.add(this.customerAreaService.getCustomers(true).subscribe(customers => {
            this.customers = customers.data;
        }));
    }

    onChangeGroup(event) {
        this.user.group = event.value;
        this.showCustomerAreaSelect();
    }

    save() {
        Object.assign(this.user, this.form.value);
        this.saving = true;
        this.userService.saveUser(this.user).subscribe(user => {
            this.saving = false;
            this.router.navigateByUrl('users');
        }, error => {
            this.saving = false;
            this.confirmDialogService.confirm('Er ging iets fout', error.message);
        });
    }

    private showCustomerAreaSelect(customerAreaIds: number[] = null) {
        this.showCustomerAreaSelection = !this.AuthorisationService.hasAnyFeature(['allCustomerAreas', 'customerAreaWithWork'], this.user) || this.AuthorisationService.hasFeature('customerAreaWithWorkRestricted', null, this.user);
        if (this.showCustomerAreaSelection) {
            this.form.addControl('customerAreaIds', new FormControl(customerAreaIds));
            this.form.addControl('customer_id', new FormControl(this.user.customer_id));
            if (this.user?.id) {
                this.filteredCustomerAreas = this.customerAreas.filter(ca => customerAreaIds?.includes(ca.id) || (+ca.customer_id === +this.user.customer_id && !!this.user.customer_id));
            }
            this.subscriptions.add(this.form.get('customer_id').valueChanges.subscribe(customer_id => {
                this.filteredCustomerAreas = this.customerAreas.filter(ca => +ca.customer_id === +customer_id);
                this.cdref.detectChanges();
            }));
        } else {
            this.form.removeControl('customerAreaIds');
            this.form.removeControl('customer_id');
        }
    }

    private getUser(userId = null) {
        const user$ = this.userService.getUser(userId);
        const areas$ = this.customerAreaService.getList();

        combineLatest([user$, areas$]).subscribe(([user, customerAreas]) => {
            this.customerAreas = customerAreas;
            this.user = user.data;
            this.setFormGroup(this.user);
        });
    }

    private setFormGroup(user: User) {
        let customerAreaIds = [];
        if (user.customer_areas) {
            customerAreaIds = user.customer_areas.map(t => t.id);
        }

        this.form = new FormGroup({
            name: new FormControl(user.name, [Validators.required, Validators.minLength(3)]),
            notifications: new FormControl(user.notifications),
            email: new FormControl(user.email, [Validators.required, Validators.email]),
            roleIds: new FormControl(user.roles.map(r => r.id))
        });

        this.showCustomerAreaSelect(customerAreaIds);
    }
}
