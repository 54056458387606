import {EventEmitter} from '@angular/core';
import {User} from './classes/user.class';
import {CustomerArea} from './classes/customerarea.class';

class StorageInterface {
    public static user: User;
}

export class LocalStorage implements StorageInterface {

    public static customerAreaChange: EventEmitter<any> = new EventEmitter();
    public static user: User;
    public static appVersion: string;

    public static get useTestAPI(): boolean {
        return !!localStorage.getItem('useTestAPI');
    }

    static get twoFaHash() {
        return localStorage.getItem('twoFaHash');
    }

    static set twoFaHash(v) {
        localStorage.setItem('twoFaHash', v);
    }

    public static setUserToken(token) {
        localStorage.setItem('access_token', token);
    }

    public static setUser(user: User) {
        this.user = user;
        localStorage.setItem('user', JSON.stringify(user));
    }

    public static setExpireTimeToken(token) {
        localStorage.setItem('token_date', new Date().toISOString());
        localStorage.setItem('expire_access_token', token);
    }

    public static getTokenDate() {
        return localStorage.getItem('token_date');
    }

    public static getExpireTimeToken() {
        return localStorage.getItem('expire_access_token');
    }

    public static getUser(): User {
        if (this.user) {
            return this.user;
        } else {
            this.user = JSON.parse(localStorage.getItem('user'));
            return JSON.parse(localStorage.getItem('user'));
        }
    }

    public static removeSnappedPointsHistory() {
        localStorage.removeItem('snappedPoints');
    }

    public static setSnappedPointsHistory(points: Object) {
        localStorage.setItem('snappedPoints', JSON.stringify(points));
    }

    public static getSnappedPointsHistory(): Object[] {
        const data = localStorage.getItem('snappedPoints');
        let snappedPointsHistory = JSON.parse(data);
        if (!snappedPointsHistory || !Array.isArray(snappedPointsHistory)) {
            snappedPointsHistory = [];
        }
        return snappedPointsHistory;
    }

    public static setMapSettings(mapSettings: Object) {
        localStorage.setItem('mapSettings', JSON.stringify(mapSettings));
    }

    public static getMapSettings(): Object {
        return JSON.parse(localStorage.getItem('mapSettings'));
    }

    public static logoutUser() {
        const twoFactorHash = this.twoFaHash;
        delete this.user;
        localStorage.clear();
        this.twoFaHash = twoFactorHash;
    }

    public static getUserToken() {
        return localStorage.getItem('access_token');
    }

    public static setLastActivity(date) {
        localStorage.setItem('lastActivity', date);
    }

    public static getLastActivity() {
        return localStorage.getItem('lastActivity');
    }

    public static saveSettings(settings: object) {
        localStorage.setItem('settings', JSON.stringify(settings));
    }

    public static getSettings() {
        const settings = JSON.parse(localStorage.getItem('settings'));
        return settings ? settings : {};
    }

    public static setCustomerArea(customerArea: CustomerArea) {
        localStorage.setItem('customerArea', JSON.stringify(customerArea));
        LocalStorage.customerAreaChange.emit();

    }

    public static getCustomerArea(): CustomerArea {
        return JSON.parse(localStorage.getItem('customerArea'));
    }


    public static saveTableColumns(settings: object) {
        localStorage.setItem('tableColumns', JSON.stringify(settings));
    }

    public static getTableColumns() {
        const settings = JSON.parse(localStorage.getItem('tableColumns'));
        return settings ? settings : null;
    }

    public static setCustomerSelectionView(view) {
        localStorage.setItem('customerSelectionView', view);
    }

    public static getCustomerSelectionView() {
        return localStorage.getItem('customerSelectionView');
    }
}
