import {Component, Inject, OnInit} from '@angular/core';
import {LocationService} from '../location-selector-dialog/location.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Pushpin} from '../../classes/pushpin.class';
import {LocalStorage} from "../../storage.class";

declare var Microsoft: any;

@Component({
    selector: 'app-calculating-length-dialog',
    templateUrl: './calculating-length-dialog.component.html',
    styleUrls: ['./calculating-length-dialog.component.scss']
})
export class CalculatingLengthDialogComponent implements OnInit {

    pushpin: Pushpin;
    walking?: boolean;
    useRoute?: boolean;

    constructor(private locationService: LocationService,
                private dialogRef: MatDialogRef<CalculatingLengthDialogComponent>,
                @Inject(MAT_DIALOG_DATA) data: {
                    pushpin: Pushpin,
                    walking?: boolean,
                    useRoute?: boolean
                }) {
        this.pushpin = data.pushpin;
        this.useRoute = data.useRoute;
    }

    ngOnInit() {
        const pointList = [new Microsoft.Maps.Location(this.pushpin.lat, this.pushpin.lng)];
        // When end_lat is filled, we are adding a middle point
        if (this.pushpin.mid_lat) {
            pointList.push(new Microsoft.Maps.Location(this.pushpin.mid_lat, this.pushpin.mid_lng));
        }
        pointList.push(new Microsoft.Maps.Location(this.pushpin.end_lat, this.pushpin.end_lng));

        const snappedPointsHistory = LocalStorage.getSnappedPointsHistory();
        this.pushpin.drivenpoints = snappedPointsHistory;
        if (this.useRoute && snappedPointsHistory && Array.isArray(snappedPointsHistory) && snappedPointsHistory.length > 1) {
            pointList.length = 0;
            snappedPointsHistory.forEach(point => {
                pointList.push(new Microsoft.Maps.Location(point['lat'], point['lng']));
            });
        }

        this.locationService.getSnappedPoints(pointList, this.walking).subscribe(result => {
            this.pushpin.snappedpoints = result;
            this.dialogRef.close();
        });
    }

}
