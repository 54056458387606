import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {User} from '../classes/user.class';
import {CustomerArea} from '../classes/customerarea.class';
import {Router} from '@angular/router';
import {CustomerAreaService} from '../services/consumer-area/customer-area.service';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {HttpClient} from '@angular/common/http';
import {CordovaService} from '../cordova.service';
import {LocalStorage} from '../storage.class';
import {CodaltComponent} from '../codalt.component';
import {FirebaseX} from '@ionic-native/firebase-x/ngx';
import {debounceTime} from 'rxjs/operators';
import {Customer} from '../classes/customer';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'app-customer-selection',
    templateUrl: './customer-selection.component.html',
    styleUrls: ['./customer-selection.component.scss']
})
export class CustomerSelectionComponent extends CodaltComponent implements OnInit, OnDestroy {
    cordovaVersion = '';

    searchText: string;
    user: User;
    customerAreas: CustomerArea[];
    customers: Customer[];
    parsedCustomerAreas: {
        letter: string;
        areas: CustomerArea[];
    }[];
    parsedCustomers: {
        letter: string;
        customers: Customer[];
    }[];
    searching = new EventEmitter<any>();

    all = false;
    fcView = new FormControl(LocalStorage.getCustomerSelectionView() ?? 'customerAreas');
    selectedCustomer: Customer;

    constructor(private router: Router,
                public customerAreaService: CustomerAreaService,
                private confirmDialogService: ConfirmDialogService,
                private firebase: FirebaseX,
                private http: HttpClient,
                private cordovaService: CordovaService) {
        super();
    }

    ngOnInit() {
        this.subscriptions.add(this.fcView.valueChanges.subscribe(show => {
            if (!this.selectedCustomer) {
                LocalStorage.setCustomerSelectionView(show);
            }
            this.parseView();
        }));
        this.subscriptions.add(this.searching.pipe(debounceTime(125)).subscribe(() => {
            this.parseView();
        }));
        this.user = LocalStorage.getUser();
        if (!this.AuthorisationService.hasAnyFeature(
            ['customerAreaGet', 'lrma', 'priceList', 'customerAreaSave', 'extraCostSave', 'decorationSave', 'userGet', 'managementDashboard'])
        ) {
            this.router.navigate(['/library']);
        }
        this.getData(true);
        this.cordovaService.setBackbuttonAction(() => {
            this.router.navigate(['/']);
        });
        if (window['cordova']) {
            this.cordovaService.getAppVersion().then((version) => {
                this.cordovaVersion = version;
            });
        } else {
            this.http.get('assets/version.json').toPromise().then(versionInfo => {
                this.cordovaVersion = versionInfo['version'];
            }, () => {

            });
        }
    }


    private parseView() {
        if (this.fcView.value === 'customers') {
            this.parsedCustomers = this.getParsedCustomers();
        } else {
            this.parsedCustomerAreas = this.getParsedCustomerAreas();
        }
    }

    getData(withFb = false) {
        this.customerAreaService.getList(this.all).then(customerAreas => {
            this.customerAreas = customerAreas;
            if (this.customerAreas.length === 1) {
                this.selectTown(this.customerAreas[0]);
            }
            if (withFb) {
                if (this.AuthorisationService.hasFeature('firebaseSubs')) {
                    customerAreas.forEach(customerArea => {
                        this.firebase.subscribe('ADMIN_CONSUMER_NOTIF_' + customerArea.id);
                    });
                } else {
                    this.firebase.unregister().then(() => {
                        this.firebase.subscribe('alldevices');
                    });
                }
            }
            this.subscriptions.add(this.customerAreaService.getCustomers(this.all).subscribe(customers => {
                this.customers = customers.data;
                this.parseView();
            }));
        });

    }

    openIfOne() {
        const customerAreas = this.customerAreas.filter(c => !this.searchText || c.name.toLowerCase().indexOf(this.searchText.toLowerCase().trim()) !== -1);
        if (customerAreas?.length === 1) {
            this.selectTown(this.customerAreas[0]);
        }
    }

    selectTown(customerArea) {
        this.router.navigate([`pushpins/map`, customerArea.id, '', '', '']);
    }

    deselectCustomer() {
        this.selectedCustomer = null;
        this.fcView.setValue('customers');
        this.parsedCustomers = this.getParsedCustomers();
    }

    selectCustomer(customer: Customer) {
        this.selectedCustomer = customer;
        if (customer.count > 1) {
            this.fcView.setValue('customerAreas');
            this.parsedCustomerAreas = this.getParsedCustomerAreas();
        } else {
            this.selectTown(this.customerAreas.find(ca => ca.customer_id === customer.id));
        }
    }

    getParsedCustomers() {
        const parsedCustomers = {};
        const parsedCustomersArray = [];
        if (this.customers) {
            this.customers.filter(c => !this.searchText || c.name.toLowerCase().indexOf(this.searchText.toLowerCase().trim()) !== -1)
                .forEach(customer => {
                    customer.count = this.customerAreas.filter(ca => ca.customer_id === customer.id).length;
                    const letter = customer.name.trim().substr(0, 1).toUpperCase();
                    if (!parsedCustomers.hasOwnProperty(letter)) {
                        parsedCustomers[letter] = [];
                    }
                    parsedCustomers[letter].push(customer);
                });

            for (const parsedAreasKey of Object.keys(parsedCustomers)) {
                parsedCustomersArray.push({
                    letter: parsedAreasKey,
                    customers: parsedCustomers[parsedAreasKey]
                });
            }

            parsedCustomersArray.sort((a, b) => {
                if (a.letter < b.letter) {
                    return -1;
                }
                if (a.letter > b.letter) {
                    return 1;
                }
                return 0;
            });
        }
        return parsedCustomersArray;
    }

    getParsedCustomerAreas() {
        const tenDayAgo = new Date();
        tenDayAgo.setUTCDate(tenDayAgo.getUTCDate() - 10);
        const parsedAreas = {};
        const parsedAreasArray = [];
        if (this.customerAreas) {
            this.customerAreas.filter(c => c.customer_id === this.selectedCustomer?.id || !this.selectedCustomer).filter(c => !this.searchText || c.name.toLowerCase().indexOf(this.searchText.toLowerCase().trim()) !== -1)
                .forEach(customerArea => {
                    if (customerArea?.calamities?.date && new Date(customerArea?.calamities?.date) < tenDayAgo) {
                        customerArea.calamities.old = true;
                    }
                    const letter = customerArea.name.trim().substr(0, 1).toUpperCase();
                    if (!parsedAreas.hasOwnProperty(letter)) {
                        parsedAreas[letter] = [];
                    }
                    parsedAreas[letter].push(customerArea);
                });

            for (const parsedAreasKey of Object.keys(parsedAreas)) {
                parsedAreasArray.push({
                    letter: parsedAreasKey,
                    areas: parsedAreas[parsedAreasKey]
                });
            }

            parsedAreasArray.sort((a, b) => {
                if (a.letter < b.letter) {
                    return -1;
                }
                if (a.letter > b.letter) {
                    return 1;
                }
                return 0;
            });
        }
        return parsedAreasArray;
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
