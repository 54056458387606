import {User} from './user.class';
import {Pushpin} from './pushpin.class';
import {Inspection} from './inspection';
import {Customer} from './customer';
import {GeoJson} from './geojson.class';

export class CustomerArea {
    id: number;
    name: string;
    area: Array<any>;
    geojson: GeoJson;
    kind: string;
    street: string;
    number: string;
    zip: string;
    city: string;
    phone: string;
    users: User[];
    pushpin: Pushpin[];
    inspections: Inspection[];
    calamities: { date: Date, count: number, old: boolean };
    dashboard: boolean;
    notifications: boolean;
    decoration_types: string[];
    customer_id: number;
    customer: Customer;
}
