<app-vvr-icon [dark]="false" [full]="false" [overlay]="false" [shadow]="false"></app-vvr-icon>
<div *ngIf="form" class="container white">
    <div class="topbar">
        <div class="title">
            <h1 class="h3 condensed">Gebruiker beheren</h1>
        </div>
        <div class="controls">
            <div class="fab small" id="btn-Exit" matTooltip="Terug naar overzicht"
                 matTooltipPosition="below"
                 routerLink="/users">
                <i class="fas fa-sign-out-alt"></i>
            </div>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col col-md-12">
            <form [formGroup]="form">
                <mat-form-field class="w-100" id="name">
                    <input formControlName="name"
                           matInput
                           name="name"
                           placeholder="Naam"
                           type="text">
                    <mat-error>Dit veld moet minimaal 3 tekens bevatten</mat-error>
                </mat-form-field>
                <mat-form-field class="w-100" id="email">
                    <input formControlName="email"
                           matInput
                           name="email"
                           placeholder="E-mailadres"
                           type="email">
                    <mat-error>Vul een correct e-mailadres in</mat-error>
                </mat-form-field>
                <ng-select [closeOnSelect]="false"
                           [hideSelected]="true"
                           [items]="roles"
                           [multiple]="true"
                           bindLabel="name"
                           bindValue="id"
                           (change)="onChangeGroup($event)"
                           formControlName="roleIds"
                           placeholder="Gebruikersrollen"></ng-select>
                <ng-select *ngIf="showCustomerAreaSelection"
                           [items]="customers"
                           bindLabel="name"
                           bindValue="id"
                           formControlName="customer_id"
                           id="customers"
                           placeholder="Opdrachtgever"></ng-select>
                <ng-select *ngIf="filteredCustomerAreas"
                           [closeOnSelect]="false"
                           [hideSelected]="true"
                           [items]="filteredCustomerAreas"
                           [multiple]="true"
                           bindLabel="name"
                           bindValue="id"
                           formControlName="customerAreaIds"
                           id="customerareas"
                           placeholder="Toegang tot klantgebieden"></ng-select>

            </form>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-md-12 col">
            <div class="d-flex justify-content-between">
                <button mat-button routerLink="/users">Terug</button>

                <button (click)="save()" [disabled]="form.invalid || saving || form.disabled" color="primary" id="btn-save"
                        mat-raised-button>
                    Opslaan <i *ngIf="saving" class="fas fa-circle-notch fa-spin"></i>
                </button>

            </div>
        </div>
    </div>
    <div class="mt-3">
        <app-user-auth-log *ngIf="user" [user]="user"></app-user-auth-log>
    </div>
</div>
