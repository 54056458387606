<app-vvr-icon [dark]="false" [full]="false" [overlay]="false" [shadow]="false"></app-vvr-icon>
<div class="container">
    <div class="topbar">
        <div class="title">
            <h1 class="h3 condensed">Reparatie</h1>
        </div>
        <div class="controls">
            <div class="fab small" id="btn-Exit" matTooltip="Terug naar meldingen"
                 matTooltipPosition="below"
                 routerLink="/repairs">
                <i class="fas fa-sign-out-alt"></i>
            </div>
        </div>
    </div>
    <div *ngIf="form">
        <form [formGroup]="form">
            <div class="row">
                <mat-form-field
                    appearance="fill"
                    class="col-md-6 col-lg-4">
                    <mat-label>Soort</mat-label>
                    <mat-select formControlName="type_id"
                                placeholder="Soort">
                        <mat-option *ngFor="let type of types"
                                    [value]="type.id">
                            {{type.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" class="col-md-6 col-lg-4">
                    <mat-label>{{type?.licence_plate ? 'Kenteken' : 'Machinenummer'}}</mat-label>
                    <input formControlName="machine_number" id="machine_number" matInput name="machine_number"
                           placeholder="{{type?.licence_plate ? 'Kenteken' : 'Machinenummer'}}"
                           type="text">
                    <mat-error *ngIf="!type?.licence_plate">Dit veld mag maximaal 255 tekens bevatten</mat-error>
                    <mat-error *ngIf="type?.licence_plate">Vul een geldig kenteken in</mat-error>
                </mat-form-field>
                <mat-form-field
                    appearance="fill"
                    class="col-md-6 col-lg-4">
                    <mat-label>Status</mat-label>
                    <mat-select formControlName="status"
                                placeholder="Status">
                        <mat-option *ngFor="let status of statusList"
                                    [value]="status">
                            {{status}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field appearance="fill" class="col-md-12">
                    <mat-label>Beschrijving</mat-label>
                    <textarea cdkAutosizeMinRows="4" cdkTextareaAutosize formControlName="description"
                              id="description" matInput
                              name="description" placeholder="Voeg hier eventuele notities en opmerkingen toe"
                              type="text"></textarea>
                    <mat-error>Dit veld is verplicht</mat-error>
                </mat-form-field>
            </div>
        </form>
        <div class="files">
            <div *ngFor="let file of repair.files" class="file">
                <div (click)="openFile(file, repair.files)"
                     [class.is-img]="['jpg', 'jpeg', 'png'].indexOf(file.ext) !== -1"
                     [style.background-image]="(file.file.substr(0,4) !== 'http' ? url : '') + file.file+'?access_token='+token|safeBgUrl">
                    <div class="icon"><i class="fas fa-file fa-3x"></i></div>
                    <div class="filename">
                        {{file.name}}
                    </div>
                </div>
            </div>
        </div>
        <div class="comments">
            <div class="title">
                <h1 class="h3 condensed">Reacties</h1>
                <ng-container *ngIf="!repair.id">
                    Je moet het reparatieformulier eerst opslaan voordat je reacties kunt plaatsen
                </ng-container>
            </div>
            <ng-container *ngIf="repair.id">
                <app-repair-comment (deleted)="deleteComment($event)"
                                    (openFile)="openFile($event, comment.files)"
                                    (placedComment)="addEmptyComment()"
                                    (uploadFiles)="commentUploadFiles($event, comment)"
                                    *ngFor="let comment of repair.comments" [repairComment]="comment">
                </app-repair-comment>
            </ng-container>
        </div>
    </div>
    <div class="controls-bottom">
        <label *ngIf="!isCordova"
               matTooltip="Upload bestand"
               matTooltipPosition="before">
            <input
                (change)="uploadFile($event)" hidden multiple type="file">
            <div class="fab big">
                <i *ngIf="!uploading" class="fas fa-upload"></i>
                <i *ngIf="uploading" class="fas fa-circle-notch fa-spin"></i>
            </div>
        </label>
        <div (click)="takePicture()"
             *ngIf="isCordova" class="fab big"
             id="btn-AddImage"
             matTooltip="Camera"
             matTooltipPosition="before">
            <i class="fas fa-camera"></i>
        </div>
        <div (click)="save(true)" [class.disabled]="saving" class="fab big primary" id="btn-Save"
             matTooltip="Opslaan"
             matTooltipPosition="before">
            <i *ngIf="!saving" class="fas fa-save"></i>
            <i *ngIf="saving" class="fas fa-circle-notch fa-spin"></i>
        </div>
    </div>
</div>

