<app-vvr-icon [dark]="false" [full]="false" [overlay]="false" [shadow]="false"></app-vvr-icon>
<div class="container">
    <div class="topbar">
        <div class="title">
            <h1 class="h3 condensed">Klanten</h1>
        </div>
        <div class="controls">
            <div class="fab small"
                 id="btn-Exit"
                 matTooltip="Terug naar startscherm"
                 matTooltipPosition="below"
                 routerLink="/">
                <i class="fas fa-sign-out-alt"></i>
            </div>
            <div class="fab small"
                 matTooltip="Werk in de buurt"
                 matTooltipPosition="below"
                 routerLink="/pushpins/map/nearby/14/52/4.8">
                <i class="fa-solid fa-location-arrow"></i>
            </div>
            <ng-container *ngIf="AuthorisationService.hasFeature('importUpload')">
                <div class="fab small" id="btn-import" matTooltip="Importeren"
                     matTooltipPosition="below"
                     routerLink="/import">
                    <i class="fas fa-file-import"></i>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-md-4">
            <ng-container *ngIf="customers?.length">
                <mat-radio-group *ngIf="!selectedCustomer" [formControl]="fcView" color="primary" aria-label="Toon klanten of gebieden">
                    <mat-radio-button class="mr-2 mt-3" value="customers">Klanten</mat-radio-button>
                    <mat-radio-button class="mt-3" value="customerAreas">Gebieden</mat-radio-button>
                </mat-radio-group>
                <button mat-button *ngIf="selectedCustomer" class="mt-3" (click)="deselectCustomer()">
                    Terug naar klanten
                </button>
            </ng-container>
        </div>
        <div class="col-md-4">
            <mat-form-field class="mr-2 w-100">
                <mat-label>
                    Zoeken
                </mat-label>
                <input (keyup)="searching.emit()"
                       (keyup.enter)="openIfOne()"
                       [(ngModel)]="searchText"
                       autocomplete="off"
                       matInput>
            </mat-form-field>
        </div>
        <div class="col-md-4 justify-content-end d-flex">
            <ng-container *ngIf="AuthorisationService.hasFeature('allCustomerAreas')">
                <mat-slide-toggle class="mt-3" color="primary" [(ngModel)]="all" (ngModelChange)="customerAreaService.reset(); getData()">Toon alle gebieden</mat-slide-toggle>
            </ng-container>
        </div>
    </div>
    <div class="customer" *ngIf="fcView.value === 'customers'">
        <div *ngFor="let letterGroup of parsedCustomers" class="letter-group">
            <h1 class="h6">{{letterGroup.letter}}</h1>
            <div class="">
                <div (click)="selectCustomer(customer)"
                     *ngFor="let customer of letterGroup.customers"
                     class="customer-area">
                    {{customer.name}}
                    <span>{{customer.count}} {{customer.count > 1 ? 'Gebieden' : 'Gebied'}}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="customer" *ngIf="fcView.value === 'customerAreas'">
        <div *ngFor="let letterGroup of parsedCustomerAreas" class="letter-group">
            <h1 class="h6">{{letterGroup.letter}}</h1>
            <div class="">
                <div (click)="selectTown(customerArea)"
                     *ngFor="let customerArea of letterGroup.areas"
                     class="customer-area"
                     [matBadgeHidden]="customerArea?.calamities?.count < 1"
                     [matBadge]="customerArea?.calamities?.count"
                     matBadgePosition="after"
                     matBadgeColor="{{customerArea.calamities.old ? 'primary' : 'accent'}}">
                    {{customerArea.name}}
                    <span>{{customerArea.kind}}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="version-info">{{cordovaVersion}}</div>
</div>
