import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {CustomerArea} from '../../classes/customerarea.class';
import {Customer} from '../../classes/customer';

@Injectable()
export class CustomerAreaService {

    constructor(private apiService: ApiService) {
    }

    private _customerAreas: CustomerArea[] = [];

    public set customerAreas(customerAreas) {
        this._customerAreas = customerAreas;
    }

    public reset() {
        this._customerAreas = [];
    }

    saveCustomer(customer: Customer) {
        return this.apiService.postCall$<Customer>('customers', customer);
    }

    getCustomers(all = false) {
        return this.apiService.getCall$<Customer[]>('customers', {all});
    }

    getAllCustomerAreas(): Promise<CustomerArea[]> {
        return this.apiService.getCall('customer-areas', {withInspections: true});
    }

    public getList(all = false): Promise<CustomerArea[]> {
        return new Promise((resolve, reject) => {
            if (this._customerAreas && this._customerAreas.length > 0) {
                resolve(this._customerAreas);
            } else {
                this.apiService.getCall('customer-areas', {all}).then((customerAreas: CustomerArea[]) => {
                    this._customerAreas = customerAreas;
                    resolve(customerAreas);
                }, err => {
                    reject(err);
                });
            }
        });
    }

    public getStats(): Promise<{ customer_area_detailed: any[], detailed: any[], customer_area_statuses: any[] }> {
        return this.apiService.getCall('customer-areas/getStats');
    }

    public getExtraCostsStats(): Promise<{ customer_area_id, total }[]> {
        return this.apiService.getCall('customer-areas/getExtraCostsStats');
    }

    public getKeyValMap(): Promise<object> {
        return new Promise((resolve, reject) => {
            if (this._customerAreas && this._customerAreas.length > 0) {
                resolve(this.toKeyVal(this._customerAreas));
            } else {
                this.apiService.getCall('customer-areas').then((decorations: CustomerArea[]) => {
                    this._customerAreas = decorations;
                    resolve(this.toKeyVal(decorations));
                }, err => {
                    reject(err);
                });
            }
        });
    }

    public getPolygon(customerAreaId: number): Promise<CustomerArea> {
        return this.apiService.getCall('customer-areas/' + customerAreaId);
    }

    public zip(customerAreaId: number, inspectionId: number) {
        return this.apiService.getBlobCall(`customer-areas/zip/${customerAreaId}/${inspectionId}`);
    }

    public delete(customerAreaId: number): Promise<Object> {
        return this.apiService.deleteCall(`customer-areas/${customerAreaId}`);
    }

    public save(customerArea: CustomerArea): Promise<CustomerArea> {
        return this.apiService.postCall('customer-areas/' + customerArea.id, customerArea);
    }

    private toKeyVal(arr: object[]) {
        const keyValMap = {};
        arr.forEach(item => {
            keyValMap[item['id']] = item;
        });
        return keyValMap;
    }

}
