import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Pushpin} from '../../classes/pushpin.class';
import {PushpinPartLog} from '../../classes/pushpinpartlog.class';
import {PushpinFilter} from '../../pushpin-filter';
import {PushpinPart} from '../../classes/pushpinpart.class';

@Injectable({
    providedIn: 'root'
})
export class PushpinService {

    private latestUpdatedAt = -1;
    private latestInspectionId = 0;
    private lastFiltersJson: string;

    constructor(private apiService: ApiService) {
    }

    public reset() {
        this.latestUpdatedAt = -1;
    }

    public allInspectionPushpins(inspectionId?: number) {
        let params = {};
        if (inspectionId) {
            params = {inspectionId};
        }
        return this.apiService.getCall$<PushpinPart[]>('all-inspection-pushpins', params);
    }

    public savePushpin(pushpin: Pushpin): Promise<Pushpin> {
        this.latestUpdatedAt = -1;
        return this.apiService.postCall('pushpin', pushpin);
    }

    public getPushpin(pushpinId): Promise<Pushpin> {
        return this.apiService.getCall(`pushpin/${pushpinId}`, {includeDeleted: true});
    }

    public deletePushpin(pushpinId, customerAreaId): Promise<boolean> {
        return this.apiService.deleteCall(`pushpin/${pushpinId}?customer_area_id=${customerAreaId}`);
    }

    public getPushpins(customerAreaId: number, filter: PushpinFilter): Promise<Pushpin[]> {

        return new Promise((resolve, reject) => {
            if (this.lastFiltersJson !== JSON.stringify(filter)) {
                this.latestUpdatedAt = -1;
                this.lastFiltersJson = JSON.stringify(filter);
            }
            this.apiService.getCall(`pushpin/all/${customerAreaId}/${this.latestUpdatedAt}`, filter)
                .then((pushpins: Pushpin[]) => {
                    if (this.latestInspectionId !== filter.inspection_id) {
                        this.latestUpdatedAt = -1;
                    }
                    if (pushpins['latest_updated_at'] !== this.latestUpdatedAt && typeof pushpins['pushpins'] !== 'undefined') {
                        resolve(pushpins['pushpins']);
                    }
                    if (this.latestInspectionId === filter.inspection_id) {
                        this.latestUpdatedAt = pushpins['latest_updated_at'];
                    }
                    this.latestInspectionId = filter.inspection_id;
                }, err => {
                    reject(err);
                });
        });
    }

    public checkNoOpenLengthmark(customerAreaId): Promise<boolean> {
        return this.apiService.getCall(`maycreatelengthmark`, {customer_area_id: customerAreaId});
    }

    public getPushpinPartLog(pushpinPartId): Promise<PushpinPartLog[]> {
        return this.apiService.getCall(`history/${pushpinPartId}`);
    }

    public getPushpinPartImagesMap(customerAreaId): Promise<object> {
        return this.apiService.getCall(`images/${customerAreaId}`);
    }

    getPushpinPartImagesMapByIds(pushpinPartIds: number[]): Promise<object> {
        return this.apiService.getCall(`images`, {pushpinPartIds});
    }
}

export class GroupedPushpinParts {

    amount: number;
    blast: boolean;
    count: boolean;
    decoration_id: number;
    exclusive_material: boolean;
    inspection_id: number;
    length: number;
    material_id: number;
    paint_color_id: number;
    primer: number;
    specific_price: boolean;
    specify_length: boolean;
    stake_out: boolean;
    status_id: number;

}
