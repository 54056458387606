import {Component, ElementRef, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {LocationService} from '../../../pushpin-detail/location-selector-dialog/location.service';
import {GpsService} from '../../../gps.service';
import {Pushpin} from '../../../classes/pushpin.class';
import {Settings} from '../../../settings.class';
import {LocalStorage} from '../../../storage.class';
import {Hectometre} from '../../../hectometre';
import {CordovaService} from '../../../cordova.service';
import {Subscription} from 'rxjs';
import {Utils} from '../../../utils.class';
import {ConfirmDialogService} from '../../../services/confirm-dialog-service/confirm-dialog.service';
import {CustomerArea} from '../../../classes/customerarea.class';

declare var cordova;

export class BingPushpin {

    icon: string;
    anchorX = 12;
    anchorY = 34;
    color: string;

    pushpin: Pushpin;

    public constructor(init?: Partial<BingPushpin>) {
        Object.assign(this, init);
    }
}

export class MapLocation {

    public lat: number;
    public lng: number;

    public constructor(init?: Partial<MapLocation>) {
        Object.assign(this, init);
    }
}


export class Polygon {

    public exteriorRing = [];
    public fillColor = 'rgba(227, 6, 19, 0.3)';
    public strokeColor = '#e30613';
    public strokeThickness = 2;
    public noFillcolorWhenZoomed = true;
    public drawing = false;

    public constructor(init?: Partial<Polygon>) {
        Object.assign(this, init);
    }
}

export class MsPolygonMetaData {
    public noFillcolorWhenZoomed: boolean;
}

declare var Microsoft: any;

@Component({
    selector: 'app-angular-bing-maps',
    templateUrl: './angular-bing-maps.component.html',
    styleUrls: ['./angular-bing-maps.component.scss']
})
export class AngularBingMapsComponent implements OnInit, OnDestroy {

    loadingSnappedPoints = false;

    manualPolygons: Polygon[];
    customerAreaPolygon: Polygon;
    customerArea: CustomerArea;
    selectionPolygon: Polygon;
    pushpins: BingPushpin[];
    mapCenter: MapLocation = {
        lat: 52.319413,
        lng: 4.576759
    };
    mapZoom = 11;

    centerChangedEvent = new EventEmitter();
    zoomChangedEvent = new EventEmitter();
    validLengthEvent = new EventEmitter();
    viewchangestartEvent = new EventEmitter<void>();

    mapClickEvent = new EventEmitter();
    mapRightClickEvent = new EventEmitter();
    pushpinDbClickEvent = new EventEmitter();
    somePointMoveEvent = new EventEmitter();
    distanceDraftLengthMark: number;
    nonRoadline = false;
    lengthMarkDraft = false;
    loading = false;
    lengthMarkUseRoute = true;
    travelModeWalking = false;

    setCenterTimer = null;
    setCenterSetZoom = false;

    private _msBingHandlers = [];
    private map: any;
    private pushpinLayer: any;
    private polylineLayer: any;
    private draftPolylineLayer: any;
    private draftPolyLine: any;
    private draftLengthMarkLastTime = 0;
    private draftLengthMarkTimeout;
    private geoLayer: any;
    private timer;
    followLocation = false;
    private geoDataAvailable = false;
    private geoDataLocationPin: any;
    private geoData;
    private msPolygons = [];
    private gpsWatchId: any;

    private hectoPinsLayer: any;
    private hectoPinsLayerM: any;
    private hectoPins: Hectometre[] = [];

    private subscriptions = new Subscription();

    constructor(private elRef: ElementRef,
                private gpsService: GpsService,
                private locationService: LocationService,
                private confirmDialogService: ConfirmDialogService,
                private cordovaService: CordovaService) {
    }

    ngOnInit() {
        this.subscriptions.add(this.cordovaService.onResume.subscribe(() => {
            this.setupGeo();
        }));
        this.lengthMarkUseRoute = typeof cordova !== 'undefined';
        this.loading = true;
        let tries = 0;
        this.timer = setInterval(() => {
            tries++;
            if (typeof Microsoft !== 'undefined') {
                this.loadMap();
                clearInterval(this.timer);
            }
            if (tries > 50) {
                clearInterval(this.timer);
                alert('Kaart kon niet worden geladen, controleer je internetverbinding');
            }
        }, 250);

    }

    setupGeo() {

        if (typeof navigator.geolocation !== 'undefined') {
            if (this.gpsWatchId) {
                navigator.geolocation.clearWatch(this.gpsWatchId);
            }
            this.geoData = {
                coords: {
                    latitude: 51.757898127643266,
                    longitude: 4.164463611531866
                }
            };
            this.geoDataAvailable = true;
            this.setCurrentLocation();
            this.gpsWatchId = navigator.geolocation.watchPosition((geoData) => {
                    this.geoData = geoData;
                    this.geoDataAvailable = true;
                    this.setCurrentLocation();
                }, () => {
                    if (Utils.isIOS() || Utils.isAndroid()) {
                        this.confirmDialogService.confirm(
                            'GPS locatie niet beschikbaar',
                            'Je hebt ons geen toegang gegeven voor je GPS locatie. ' +
                            'Dit is nodig om de exacte locatie van het probleem vast te stellen.',
                            'Opnieuw proberen', 'Geen GPS gebruiken').then(() => {
                            this.setupGeo();
                        }, () => {
                        });
                    }
                },
                {
                    enableHighAccuracy: true,
                    maximumAge: 3000
                });
        } else {
            setTimeout(() => {
                this.setupGeo();
            }, 3000);
        }

    }

    clearAllHectoPins(clearSource?: boolean) {
        if (clearSource) {
            this.hectoPins = [];
        }
        if (this.hectoPinsLayerM) {
            this.map.layers.remove(this.hectoPinsLayerM);
        }
        this.hectoPinsLayerM = new Microsoft.Maps.Layer();
        if (this.hectoPinsLayer) {
            this.map.layers.remove(this.hectoPinsLayer);
        }
        this.hectoPinsLayer = new Microsoft.Maps.Layer();
    }

    addHectoPins(hectoPins: Hectometre[]) {
        this.hectoPins.push(...hectoPins);
        const pinsToDraw = [];
        const pinsToDrawM = [];
        this.hectoPins.forEach(hectoPin => {
            const pin = new Microsoft.Maps.Pushpin(
                new Microsoft.Maps.Location(hectoPin.lat, hectoPin.lng),
                {
                    icon: Settings.getHecto(hectoPin.waynumber, hectoPin.hectometre, (hectoPin.direction || hectoPin.letter)),
                    anchor: new Microsoft.Maps.Point(20, 40)
                }
            );
            pinsToDraw.push(pin);
            // filter only every 1KM
            if ((hectoPin.hectometre + '').substr(-1) === '0' && (hectoPin.direction === 'Re' || hectoPin.direction === null) && !hectoPin.letter) {
                pinsToDrawM.push(new Microsoft.Maps.Pushpin(
                    new Microsoft.Maps.Location(hectoPin.lat, hectoPin.lng),
                    {
                        icon: Settings.getHecto(hectoPin.waynumber, hectoPin.hectometre, ''),
                        anchor: new Microsoft.Maps.Point(20, 40)
                    }
                ));
            }
        });
        this.clearAllHectoPins();
        this.hectoPinsLayerM.add(pinsToDrawM);
        this.hectoPinsLayer.add(pinsToDraw);
        this.drawHectoPins();
    }

    setZoom(zoomLevel: number) {
        if (zoomLevel) {
            this.mapZoom = zoomLevel;
        }
        this.setView();
    }

    setCenter(mapCenter: MapLocation) {
        if (mapCenter && (
            mapCenter.lat !== this.mapCenter.lat ||
            mapCenter.lng !== this.mapCenter.lng)) {
            this.mapCenter = mapCenter;
        }
        console.log('center GPS func');
        this.setView();
    }

    public centerOnGps() {
        this.mapCenter = {
            lat: this.geoData.coords.latitude,
            lng: this.geoData.coords.longitude
        };
        if (this.setCenterSetZoom) {
            this.setZoom(17);
            this.setCenterSetZoom = false;
        }
        this.setCenterSetZoom = true;
        clearTimeout(this.setCenterTimer);
        this.setCenterTimer = setTimeout(() => {
            this.setCenterSetZoom = false;
        }, 1200);
        this.followLocation = true;
        this.setView();
    }

    setMapStyle(roadStyle: boolean) {
        if (roadStyle) {
            this.map.setView({animate: true, mapTypeId: 'r'});
        } else {
            this.map.setView({animate: true, mapTypeId: 'a'});
        }
    }

    drawDraftLengthmark() {
        if (this.pushpins) {
            this.lengthMarkDraft = false;
            const bingPushpin = this.pushpins.find(p => p.pushpin.draft);
            // Drawing draft line to current GPS location
            if (bingPushpin) {

                this.lengthMarkDraft = true;
                const pointList = [];

                // When end_lat is filled, we are adding a middle point
                if (bingPushpin.pushpin.end_lat) {
                    pointList.push(new Microsoft.Maps.Location(bingPushpin.pushpin.lat, bingPushpin.pushpin.lng));
                    pointList.push(new Microsoft.Maps.Location(+this.mapCenter.lat, +this.mapCenter.lng));
                    pointList.push(new Microsoft.Maps.Location(
                        bingPushpin.pushpin.end_lat,
                        bingPushpin.pushpin.end_lng
                    ));
                }

                pointList.push(new Microsoft.Maps.Location(bingPushpin.pushpin.lat, bingPushpin.pushpin.lng));
                if (this.lengthMarkUseRoute) {
                    const snappedPointsHistory = LocalStorage.getSnappedPointsHistory();
                    if (snappedPointsHistory) {
                        snappedPointsHistory.forEach(point => {
                            pointList.push(new Microsoft.Maps.Location(point['lat'], point['lng']));
                        });
                    }
                } else {
                    pointList.push(new Microsoft.Maps.Location(+this.mapCenter.lat, +this.mapCenter.lng));
                }

                const diffTime = this.draftLengthMarkLastTime + 1400;
                const curTime = new Date().getTime();
                if (diffTime < curTime) {
                    this.drawLengtMarkDrafPolygon(pointList);
                } else {
                    clearTimeout(this.draftLengthMarkTimeout);
                    this.draftLengthMarkTimeout = setTimeout(() => {
                        this.drawLengtMarkDrafPolygon(pointList);
                    }, (diffTime - curTime));
                }
            } else {
                if (this.draftPolylineLayer) {
                    clearTimeout(this.draftLengthMarkTimeout);
                    this.map.layers.remove(this.draftPolylineLayer);
                    this.draftPolylineLayer = null;
                    this.draftLengthMarkLastTime = 0;
                }
            }
        }
    }

    drawPushpins() {
        if (this.pushpinLayer) {
            this.map.layers.remove(this.pushpinLayer);
            this.map.layers.remove(this.polylineLayer);
        }
        if (this.pushpins) {
            const polylinesToDraw = [];
            const pushpinsToDraw = [];

            this.pushpins.forEach(bingPushpin => {
                // Drawing draft line to current GPS location
                if (!bingPushpin.pushpin.draft) {
                    // Non-draft lines and points
                    if (bingPushpin.pushpin.lengthmark) {
                        if (bingPushpin.pushpin.snappedpoints) {
                            polylinesToDraw.push(new Microsoft.Maps.Polyline(
                                bingPushpin.pushpin.snappedpoints['snappedPoints'],
                                {
                                    strokeColor: bingPushpin.color ? '#' + bingPushpin.color : '#e30613',
                                    strokeThickness: 2
                                }
                            ));

                            const endPoint = bingPushpin.pushpin.snappedpoints['snappedPoints'].slice(-1).pop();
                            pushpinsToDraw.push(new Microsoft.Maps.Pushpin(
                                new Microsoft.Maps.Location(endPoint.latitude, endPoint.longitude),
                                {
                                    icon: bingPushpin.icon,
                                    anchor: new Microsoft.Maps.Point(bingPushpin.anchorX, bingPushpin.anchorY)
                                }
                            ));
                        }
                    }
                    pushpinsToDraw.push(new Microsoft.Maps.Pushpin(
                        new Microsoft.Maps.Location(bingPushpin.pushpin.lat, bingPushpin.pushpin.lng),
                        {
                            icon: bingPushpin.icon,
                            anchor: new Microsoft.Maps.Point(bingPushpin.anchorX, bingPushpin.anchorY)
                        }
                    ));
                }
            });
            this.polylineLayer = new Microsoft.Maps.Layer();
            this.polylineLayer.add(polylinesToDraw);
            this.map.layers.insert(this.polylineLayer);

            Microsoft.Maps.loadModule('Microsoft.Maps.Clustering', () => {
                this.pushpinLayer = new Microsoft.Maps.ClusterLayer(pushpinsToDraw,
                    {
                        clusteredPinCallback: function (clusterPin) {
                            clusterPin.setOptions({color: '#e30613'});
                        }
                    });
                this.map.layers.insert(this.pushpinLayer);
                this.loading = false;
            });
        }

    }

    updateManualPolygons(polygons: Polygon[]) {
        this.manualPolygons = polygons;
        if (this.map) {
            this.drawPolygons();
        }
    }

    updateSelectionPolygons(polygon: Polygon) {
        this.selectionPolygon = polygon;
        if (this.map) {
            this.drawPolygons();
        }
    }

    updateCustomerAreaPolygon(polygon: Polygon, customerArea: CustomerArea) {
        this.customerAreaPolygon = polygon;
        if (this.map) {
            this.drawPolygons();
        }
        this.customerArea = customerArea;
    }

    updatePushpins(pushpins: BingPushpin[]) {
        this.pushpins = pushpins;
        if (this.map) {
            this.drawPushpins();
            this.drawDraftLengthmark();
        }
    }

    drawPolygons(onlyWhenDrawing = false) {

        if ((this.manualPolygons || this.customerAreaPolygon || this.selectionPolygon) &&
            (!onlyWhenDrawing || this.manualPolygons.find(p => p.drawing) || this.selectionPolygon)) {
            this.map.entities.clear();
            this.msPolygons = [];
            let allPolygons = [];

            const geopolygons = [];

            this.customerArea?.geojson?.features.forEach(feature => {
                feature.geometry.coordinates.forEach(polygonGroups => {
                    const polygons = polygonGroups.map(polygon =>
                        polygon.map(coord => new Microsoft.Maps.Location(coord[1], coord[0]))
                    );
                    geopolygons.push(polygons);
                });
            });

            if (geopolygons.length > 1) {
                geopolygons.forEach(geoPolygon => {
                    const msPolygon = new Microsoft.Maps.Polygon(geoPolygon, {
                        fillColor: 'rgba(227, 6, 19, 0.1)',
                        strokeColor: '#e30613',
                        strokeThickness: 2
                    });

                    msPolygon.metadata = new MsPolygonMetaData();
                    Object.assign(msPolygon.metadata, {
                        fillColor: 'rgba(227, 6, 19, 0.1)',
                        noFillcolorWhenZoomed: false
                    });

                    this.msPolygons.push(msPolygon);
                    this.map.entities.push(msPolygon);
                });
            }
            if (geopolygons.length === 0) {
                allPolygons.push(this.customerAreaPolygon);
            }

            if (this.manualPolygons) {
                allPolygons = allPolygons.concat(this.manualPolygons);
            }
            if (this.selectionPolygon) {
                allPolygons.push(this.selectionPolygon);
            }

            allPolygons.forEach(polygon => {
                let msPolygon = null;
                const msExteriorRing = [];
                if (polygon.exteriorRing && !polygon.isStreet) {
                    polygon.exteriorRing.forEach((location, index) => {
                        msExteriorRing.push(new Microsoft.Maps.Location(
                            location['lat'],
                            location['lng']
                        ));

                        if (polygon.drawing) {
                            const pushpin = new Microsoft.Maps.Pushpin(
                                new Microsoft.Maps.Location(location['lat'], location['lng']),
                                {
                                    text: 'P' + (index + 1),
                                    color: '#e30613',
                                    draggable: true
                                });
                            this.map.entities.push(pushpin);
                            Microsoft.Maps.Events.addHandler(pushpin, 'drag', (e) => {

                                location['lat'] = e.target.geometry.y;
                                location['lng'] = e.target.geometry.x;
                                if (msPolygon) {
                                    const upMsExteriorRing = [];
                                    polygon.exteriorRing.forEach((lct, i) => {
                                        upMsExteriorRing.push(new Microsoft.Maps.Location(
                                            lct['lat'],
                                            lct['lng']
                                        ));
                                    });
                                    msPolygon.setLocations(upMsExteriorRing);
                                }
                                this.somePointMoveEvent.emit(e);
                            });
                            Microsoft.Maps.Events.addHandler(pushpin, 'dblclick', (e) => {
                                polygon.exteriorRing.splice(polygon.exteriorRing.indexOf(location), 1);
                                if (msPolygon) {
                                    const upMsExteriorRing = [];
                                    polygon.exteriorRing.forEach((lct, i) => {
                                        upMsExteriorRing.push(new Microsoft.Maps.Location(
                                            lct['lat'],
                                            lct['lng']
                                        ));
                                    });
                                    msPolygon.setLocations(upMsExteriorRing);
                                    this.map.entities.removeAt(this.map.entities.indexOf(pushpin));
                                }
                            });


                        }
                    });
                } else if (polygon.isStreet) {

                    polygon.exteriorRing.forEach((location, index) => {
                        if (polygon.drawing) {
                            const pushpin = new Microsoft.Maps.Pushpin(
                                new Microsoft.Maps.Location(location['lat'], location['lng']),
                                {
                                    text: 'P' + (index + 1),
                                    color: '#e30613'
                                });
                            this.map.entities.push(pushpin);
                            Microsoft.Maps.Events.addHandler(pushpin, 'dblclick', (e) => {
                                polygon.exteriorRing.splice(polygon.exteriorRing.indexOf(location), 1);
                                this.pushpinDbClickEvent.emit();
                            });
                        }
                    });
                    if (polygon.snappedPoints && polygon.snappedPoints.length > 1) {
                        polygon.snappedPoints.forEach(p => {
                            msExteriorRing.push(new Microsoft.Maps.Location(
                                p['lat'],
                                p['lng']
                            ));
                        });
                    }
                }
                if (polygon.drawing && !polygon.isStreet) {
                    if (typeof cordova !== 'undefined') {
                        msExteriorRing.push(new Microsoft.Maps.Location(
                            this.mapCenter.lat,
                            this.mapCenter.lng
                        ));
                    }
                }
                if (msExteriorRing.length > 1) {
                    const rings = [msExteriorRing];
                    if (polygon.holes && polygon.holes) {
                        const holes = polygon.holes?.map(hole => hole.map(location => {
                            return new Microsoft.Maps.Location(
                                location['lat'],
                                location['lng']
                            );
                        }));
                        rings.push(...holes);
                    }
                    msPolygon = new Microsoft.Maps.Polygon(rings, {
                        fillColor:
                            polygon.noFillcolorWhenZoomed
                                ? (
                                    this.map.getZoom() > Settings.ZOOMLEVEL_TRESHOLD_FILLCOLOR
                                        ? 'rgba(0, 0, 0, 0)' : polygon.fillColor
                                ) : polygon.fillColor,
                        strokeColor: polygon.strokeColor,
                        strokeThickness: polygon.strokeThickness
                    });
                    msPolygon.metadata = new MsPolygonMetaData();
                    msPolygon.metadata.fillColor = polygon.fillColor;
                    msPolygon.metadata.noFillcolorWhenZoomed = polygon.noFillcolorWhenZoomed;
                    this.msPolygons.push(msPolygon);
                }
                this.map.entities.push(msPolygon);


            });
        }
    }

    ngOnDestroy(): void {
        navigator.geolocation.clearWatch(this.gpsWatchId);
        if (this.map) {
            this.map.dispose();
        }
        this.subscriptions.unsubscribe();
    }

    private drawLengtMarkDrafPolygon(pointList: any[]) {
        this.loadingSnappedPoints = true;
        this.locationService.getSnappedPoints(pointList, this.travelModeWalking).subscribe((data) => {
            this.distanceDraftLengthMark = Math.round(data['totalLength']);
            this.nonRoadline = data['snappedPoints'].length === 2;
            this.validLengthEvent.emit(this.distanceDraftLengthMark > 0);
            if (this.distanceDraftLengthMark > 0) {
                if (!this.draftPolyLine) {
                    this.draftPolyLine = new Microsoft.Maps.Polyline(
                        data['snappedPoints'],
                        {
                            strokeColor: '#FFDD00',
                            strokeThickness: 6
                        }
                    );
                } else {
                    this.draftPolyLine.setLocations(data['snappedPoints']);
                }
                if (!this.draftPolylineLayer) {
                    this.draftPolylineLayer = new Microsoft.Maps.Layer();
                    this.draftPolylineLayer.add(this.draftPolyLine);
                    this.map.layers.insert(this.draftPolylineLayer);
                }
                this.draftLengthMarkLastTime = new Date().getTime();
            }
            this.loadingSnappedPoints = false;
        }, () => {
            this.loadingSnappedPoints = false;
        });
    }

    private setView(setZoom = true) {
        if (this.map && this.mapCenter?.lat) {
            if (setZoom) {
                this.map.setView({
                    center: new Microsoft.Maps.Location(this.mapCenter.lat, this.mapCenter.lng),
                    zoom: this.mapZoom
                });
            } else {
                this.map.setView({
                    center: new Microsoft.Maps.Location(this.mapCenter.lat, this.mapCenter.lng)
                });
            }
        }
    }

    private loadMap() {
        this.map = new Microsoft.Maps.Map(this.elRef.nativeElement.querySelector('#map'), {
            credentials: 'AvaQB1Fe_AeAII18YRy4SY0OzNnb05Js89lC1HOkUgyohnWeDlMfe5Jq7zJRI20g'
        });

        this.drawPushpins();

        this.map.setOptions({
            showDashboard: false,
            enableHighDpi: true,
            enableClickableLogo: false,
            enableSearchLogo: false,
            showCopyright: false,
            tileBuffer: 40
        });
        this._msBingHandlers.push(Microsoft.Maps.Events.addHandler(this.map, 'viewchangestart', () => {
            this.viewchangestartEvent.next();
        }));

        this._msBingHandlers.push(Microsoft.Maps.Events.addHandler(this.map, 'click', (event) => {
            this.mapClickEvent.next(event);
        }));

        this._msBingHandlers.push(Microsoft.Maps.Events.addHandler(this.map, 'rightclick', (event) => {
            this.mapRightClickEvent.next({
                lat: event.location.latitude,
                lng: event.location.longitude
            });
        }));

        this._msBingHandlers.push(Microsoft.Maps.Events.addHandler(this.map, 'viewchangeend', () => {
            this.zoomChangedEvent.next(this.map.getZoom());

            this.drawHectoPins();

            const center = this.map.getCenter();
            this.centerChangedEvent.next({
                lat: center.latitude,
                lng: center.longitude
            });

            this.mapCenter.lat = center.latitude;
            this.mapCenter.lng = center.longitude;

            this.drawDraftLengthmark();
            this.drawPolygons(true);
        }));

        this.setView();
        this.drawPushpins();

        this._msBingHandlers.push(Microsoft.Maps.Events.addHandler(this.map, 'viewchangeend', () => {
            const mapCenter = this.map.getCenter();

            const latDiff = Math.abs(mapCenter['latitude'] - this.geoData.coords['latitude']);
            const longDiff = Math.abs(mapCenter['longitude'] - this.geoData.coords['longitude']);
            if (this.followLocation) {
                if (latDiff > 0.00001 || longDiff > 0.00001) {
                    this.followLocation = false;
                    console.log('Disabled followLocation because of enough user interaction');
                } else {
                    console.log('Updating center because following GPS location, diff:', latDiff, longDiff);
                    this.mapCenter.lat = this.geoData.coords['latitude'];
                    this.mapCenter.lng = this.geoData.coords['longitude'];
                    this.setView(false);
                }
            }

            if (this.map.getZoom() > Settings.ZOOMLEVEL_TRESHOLD_FILLCOLOR) {
                this.msPolygons.forEach(msPolygon => {
                    if (msPolygon.metadata.noFillcolorWhenZoomed) {
                        msPolygon.setOptions({
                            fillColor: 'rgba(0, 0, 0, 0)',
                            strokeThickness: 2
                        });
                    }
                });
            } else {
                this.msPolygons.forEach(msPolygon => {
                    msPolygon.setOptions({
                        fillColor: msPolygon.metadata.fillColor,
                        strokeThickness: 2
                    });
                });
            }


        }));

        this.setupGeo();

        this.drawPolygons();
        this.drawDraftLengthmark();
    }

    private drawHectoPins() {
        if (this.map._mapHasLoaded) {
            if (this.map.getZoom() < Settings.ZOOMLEVEL_TRESHOLD_HECTOMETRE_M && this.hectoPinsLayerM) {
                this.map.layers.remove(this.hectoPinsLayerM);
            }
            if (this.map.getZoom() < Settings.ZOOMLEVEL_TRESHOLD_HECTOMETRE && this.hectoPinsLayer) {
                this.map.layers.remove(this.hectoPinsLayer);
            }

            if (this.map.getZoom() >= Settings.ZOOMLEVEL_TRESHOLD_HECTOMETRE && this.map.getZoom() <= Settings.ZOOMLEVEL_TRESHOLD_HECTOMETRE_M) {
                if (this.hectoPinsLayer) {
                    this.map.layers.remove(this.hectoPinsLayer);
                }
                if (this.hectoPinsLayerM) {
                    this.map.layers.insert(this.hectoPinsLayerM);
                }
            }
            if (this.map.getZoom() > Settings.ZOOMLEVEL_TRESHOLD_HECTOMETRE_M && this.hectoPinsLayer) {
                this.map.layers.insert(this.hectoPinsLayer);
            }
        }
    }

    private setCurrentLocation() {
        console.log('set current location pin');
        if (this.lengthMarkDraft) {
            const snappedPointsHistory = LocalStorage.getSnappedPointsHistory();
            const currentPoint = {lat: +this.geoData.coords.latitude, lng: +this.geoData.coords.longitude};
            if (snappedPointsHistory.length > 1) {
                const lastPoint = snappedPointsHistory[snappedPointsHistory.length - 1];
                if (lastPoint['lat'] !== currentPoint.lat && lastPoint['lng'] !== currentPoint.lng) {
                    snappedPointsHistory.push(currentPoint);
                }
            } else {
                snappedPointsHistory.push(currentPoint);
            }
            LocalStorage.setSnappedPointsHistory(snappedPointsHistory);
        }
        if (!this.geoDataLocationPin) {
            this.geoLayer = new Microsoft.Maps.Layer();
            this.geoDataLocationPin = new Microsoft.Maps.Pushpin(
                new Microsoft.Maps.Location(this.geoData.coords.latitude, this.geoData.coords.longitude),
                {
                    // tslint:disable-next-line:max-line-length
                    icon: '<?xml version="1.0" encoding="UTF-8"?><svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><!-- Generator: Sketch 48.2 (47327) - http://www.bohemiancoding.com/sketch --><title>icons/current</title><desc>Created with Sketch.</desc><defs><path d="M9.99799378,20 C4.48690942,20 0.00200621928,15.5148718 0,10.0035111 C0.00200621928,4.48813763 4.48690942,0.00100315995 10,0 C15.5110844,0 19.9979938,4.48613131 20,10.0005016 C20,15.5138687 15.5130906,20 9.99799378,20" id="path-1"></path><filter x="-30.0%" y="-30.0%" width="160.0%" height="160.0%" filterUnits="objectBoundingBox" id="filter-2"><feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset><feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur><feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.4 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix></filter></defs><g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="icons/current"><g id="Group-3" transform="translate(6.000000, 6.000000)"><g id="Path"><use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"></use><use fill="#FFFFFF" fill-rule="evenodd" xlink:href="#path-1"></use></g><path d="M10,2.00631991 C5.59333935,2.00732307 2.0082255,5.59462306 2.00621928,10.0035111 C2.0082255,14.4083864 5.59133313,17.9936801 9.99799378,17.9936801 C14.4066606,17.9936801 17.9937807,14.4083864 17.9937807,10.0015047 C17.9917745,5.59261674 14.4066606,2.00631991 10,2.00631991" id="Path" fill="#ffdd00"></path></g></g></g></svg>',
                    anchor: new Microsoft.Maps.Point(16, 16)
                }
            );
            this.geoLayer.add(this.geoDataLocationPin);
            this.map.layers.insert(this.geoLayer);
        } else {
            this.geoDataLocationPin.setLocation(new Microsoft.Maps.Location(this.geoData.coords.latitude, this.geoData.coords.longitude));
        }

        if (this.followLocation) {
            this.mapCenter = {
                lat: this.geoData.coords.latitude,
                lng: this.geoData.coords.longitude
            };
            this.setZoom(16);
            this.setView(false);
        }

    }


}
