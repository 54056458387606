import {AfterContentChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {PushpinDetailComponent} from '../pushpin-detail/pushpin-detail.component';
import {PushpinService} from '../services/pushpin/pushpin.service';
import {CustomerAreaService} from '../services/consumer-area/customer-area.service';
import {CustomerArea} from '../classes/customerarea.class';
import {PushpinMapComponent} from './pushpin-map/pushpin-map.component';
import {PushpinListComponent} from './pushpin-list/pushpin-list.component';
import {CordovaService} from '../cordova.service';
import {PushpinSelectionComponent} from './pushpin-selection/pushpin-selection.component';
import {PushpinFilterComponent} from './pushpin-filter/pushpin-filter.component';
import {ManualAreasComponent} from './pushpin-map/manual-areas/manual-areas.component';
import {combineLatest, interval, Subscription} from 'rxjs';
import {Platform} from '@angular/cdk/platform';
import {CodaltComponent} from '../codalt.component';
import {Inspection} from '../classes/inspection';
import {debounceTime, startWith} from 'rxjs/operators';
import {ChooseInspectionDialogComponent} from '../choose-inspection/choose-inspection-dialog/choose-inspection-dialog.component';
import {PushpinMoveComponent} from './pushpin-move/pushpin-move.component';

@Component({
    selector: 'app-pushpin-overview',
    templateUrl: './pushpin-overview.component.html',
    styleUrls: ['./pushpin-overview.component.scss']
})
export class PushpinOverviewComponent extends CodaltComponent implements OnInit, OnDestroy, AfterContentChecked {

    public inspection: Inspection;
    public customerArea: CustomerArea;
    public view = 'map';
    @ViewChild('pushpinDetail')
    public pushpinDetailComponent: PushpinDetailComponent;
    @ViewChild('selection')
    public selectionComponent: PushpinSelectionComponent;
    @ViewChild('filter', {static: true})
    public filterComponent: PushpinFilterComponent;
    @ViewChild('manualAreas', {static: true})
    public manualAreasComponent: ManualAreasComponent;
    @ViewChild('move', {static: true})
    public pushpinMoveComponent: PushpinMoveComponent;
    public showPriceSelectionBar: boolean;
    @ViewChild('pushpinMap', {static: true})
    public pushpinMapComponent: PushpinMapComponent;
    private inspectionSubs = new Subscription();
    private showMap = true;
    @ViewChild('pushpinList', {static: true})
    private pushpinListComponent: PushpinListComponent;

    private prevView: string;

    nearby = false;

    constructor(private route: ActivatedRoute,
                private dialog: MatDialog,
                private router: Router,
                private pushpinService: PushpinService,
                private cordovaService: CordovaService,
                public platform: Platform,
                private cdref: ChangeDetectorRef,
                private customerAreaService: CustomerAreaService) {
        super();
    }

    ngAfterContentChecked() {
        this.cdref.detectChanges();
    }

    ngOnInit() {
        this.showMap = true;
        this.cordovaService.setBackbuttonAction(() => {
            this.router.navigate([`/`]);
        });
        this.subscriptions.add(this.route.params.subscribe(params => {
            if (params['view'] === 'map' && this.prevView?.indexOf('-detail') !== -1) {
                this.pushpinMapComponent.coordinatesChange(params['lat'], params['lng'], params['zoom']);
            }
            const inspectionId = +params['inspection'];
            if (params['id'] === 'nearby') {
                this.view = params['view'];
                if (params['id'] === 'nearby' && !this.nearby) {
                    this.nearby = true;
                    setTimeout(() => {
                        this.initInspection();
                    });
                }
            } else if (!this.customerArea && isNaN(+params['id']) && params['view'] === 'map-detail' && params['view'] === 'list-detail') {
                this.router.navigate([``]);
            } else {
                this.view = params['view'];
                this.pushpinMapComponent.selectedPushpins = [];
                if (!['map-detail', 'list-detail'].includes(this.view)
                    && (!this.customerArea || (this.inspection?.id !== inspectionId && (!isNaN(inspectionId) || this.inspection)))) {

                    if (this.customerArea?.id !== (+params['id'])) {
                        this.customerAreaService.getPolygon(+params['id']).then(customerArea => {
                            this.initInspection(customerArea, inspectionId, params);
                        });
                    } else {
                        this.initInspection(this.customerArea, inspectionId, params);
                    }
                }
            }

            this.prevView = params['view'];
        }));
    }

    reloadPushpins() {
        if (this.customerArea) {
            this.pushpinService.getPushpins(this.customerArea.id, this.filterComponent.filter).then(pushpins => {
                this.pushpinMapComponent.reloadPushpins(pushpins);
                this.pushpinListComponent.reloadPushpins(pushpins);
                if (typeof this.selectionComponent !== 'undefined') {
                    this.selectionComponent.setVisiblePushpins(pushpins);
                    this.selectionComponent.getPushpinPartsAndCalculatePrices();
                }
            });
        }
    }

    ngOnDestroy(): void {
        this.inspectionSubs.unsubscribe();
        this.pushpinService.reset();
        this.cordovaService.setBackbuttonExitAction();
    }

    chooseInspection(customerArea) {
        const dialogRef = this.dialog.open(ChooseInspectionDialogComponent, {
            panelClass: 'pushpin-dialog',
            data: customerArea.inspections,
            disableClose: true
        });
        const subs = dialogRef.afterClosed().subscribe(inspection => {
            const params = this.route.snapshot.params;
            if (inspection) {
                this.router.navigate([
                    'pushpins',
                    params['view'],
                    params['id'],
                    params['zoom'] || 0,
                    params['lat'] || 0,
                    params['lng'] || 0,
                    {inspection: inspection.id}
                ]);
            } else {
                this.router.navigateByUrl('/customer-selection');
            }
            subs.unsubscribe();
        });
    }

    private initInspection(customerArea?: CustomerArea, inspectionId?: number, params?: any) {
        this.inspectionSubs.unsubscribe();
        this.inspectionSubs = new Subscription();
        this.pushpinService.reset();
        if (inspectionId) {
            this.inspection = customerArea.inspections.find(i => i.id === (inspectionId));
            this.filterComponent.filter.inspection_id = this.inspection.id;
            this.setCA(customerArea);
        } else if (customerArea?.inspections?.length === 1) {
            this.router.navigate([
                'pushpins',
                params['view'],
                params['id'],
                params['zoom'] || 0,
                params['lat'] || 0,
                params['lng'] || 0,
                {inspection: customerArea.inspections[0].id}
            ]);
        } else {
            this.inspection = null;
            delete this.filterComponent.filter.inspection_id;
            this.setCA(customerArea);
        }
    }

    private setCA(customerArea) {
        this.showPriceSelectionBar = window.matchMedia('(min-width: 1100px)').matches && (
            (this.AuthorisationService.hasFeature('priceList') && this.inspection?.inspection_status_id > 2)
            || this.AuthorisationService.hasFeature('showPushpinSelectionBarAlways'));
        this.customerArea = customerArea;

        this.pushpinListComponent.setCustomerArea(this.customerArea, this.inspection);
        this.pushpinMapComponent.setCustomerArea(this.customerArea, this.inspection);
        this.filterComponent.setCustomerArea(this.customerArea, this.inspection).then(() => {
            this.pushpinService.getPushpins(this.customerArea?.id, this.filterComponent.filter).then(pushpins => {
                this.pushpinMapComponent.reloadPushpins(pushpins);
                this.pushpinListComponent.initKeyValMapData(pushpins);
                if (this.showPriceSelectionBar) {
                    setTimeout(() => {
                        this.selectionComponent.setCustomerArea(this.customerArea, this.inspection);
                        if (typeof this.selectionComponent !== 'undefined') {
                            this.selectionComponent.setVisiblePushpins(pushpins);
                        }
                        if (typeof this.pushpinMoveComponent !== 'undefined') {
                            this.pushpinMoveComponent.setVisiblePushpins(pushpins);
                        }
                    }, 500);
                } else if (!this.inspection) {
                    this.chooseInspection(customerArea);
                }
            });
            this.inspectionSubs.add(combineLatest([this.filterComponent.filterEmitter.pipe(debounceTime(500)), interval(10000).pipe(startWith(0))])
                .subscribe(() => {
                    this.pushpinService.getPushpins(this.customerArea?.id, this.filterComponent.filter).then(pushpins => {
                        this.pushpinMapComponent.reloadPushpins(pushpins);
                        this.pushpinListComponent.reloadPushpins(pushpins);
                        if (typeof this.selectionComponent !== 'undefined') {
                            this.selectionComponent.setVisiblePushpins(pushpins);
                        }
                        if (typeof this.pushpinMoveComponent !== 'undefined') {
                            this.pushpinMoveComponent.setVisiblePushpins(pushpins);
                        }
                    });
                })
            );
        }, () => {

        });
    }
}
