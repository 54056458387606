<div class="pushpin-selection-container">
    <mat-tab-group (selectedTabChange)="tabChange($event)" mat-stretch-tabs>
        <mat-tab label="Schouwronde">
            <div class="selection">
                <div *ngIf="customerArea && !AuthorisationService.hasFeature('inspectionSave')" class="mb-2">
                    <button mat-stroked-button (click)="chooseInspection.emit()" class="w-100">Selecteer een schouwronde</button>
                </div>
                <div *ngIf="customerArea && AuthorisationService.hasFeature('inspectionSave')">
                    <app-choose-inspection (inspectionChanged)="inspectionChange()" [customerArea]="customerArea" [inspection]="inspection"></app-choose-inspection>
                </div>
                <div *ngIf="inspection && inspection.inspection_status">
                    <div>
                        <div class="bold">
                            Status: {{inspection.inspection_status.name}}
                        </div>
                        <div class="infotext">
                            <ng-container *ngIf="inspection?.inspection_status_id === 1">
                                <p>
                                    Sinds: {{inCustomerAreaStatusSince | date : 'd MMM yyyy H:mm'}} uur<br>
                                    Geschouwde markeringsitems: {{totalParts}}
                                </p>
                            </ng-container>
                            <ng-container *ngIf="inspection?.inspection_status_id === 2 && AuthorisationService.hasFeature('selectionToConsumer')">
                                <div>
                                    <p>
                                        Geschouwde markeringsitems dienen gecontroleerd te worden voor de klant deze kan
                                        zien.
                                        De klant ziet geen markeringsitems met de status "Concept". Standaard staan punten
                                        die
                                        niet onder "altijd controleren" vallen op "Geschouwd".
                                    </p>
                                    <p>
                                        Vrijgegeven markeringsitems: {{selectedParts}} van {{totalParts}}
                                        op {{selectedPushpins}} locaties.
                                    </p>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="inspection?.inspection_status_id === 3">
                                <div>
                                    <p>
                                        Van Velsen heeft voor {{customerArea.kind}} {{customerArea.name}} {{totalParts}}
                                        punten geschouwd. U kunt nu selecteren welke punten u wilt laten uitvoeren.
                                        Voor aanpassing van de extra kosten (zoals bijvoorbeeld de verkeersregelaars), kunt
                                        u een hercontrole aanvragen.
                                        Geselecteerde markeringsitems blijven geselecteerd.
                                    </p>
                                    <p>
                                        Selectie afgerond? Ga naar het tabblad 'Status' en kies 'Geselecteerde
                                        markeringsitems in opdracht geven'.
                                    </p>
                                    <p>
                                        Geselecteerde markeringsitems: {{selectedParts}} van {{totalParts}}
                                        op {{selectedPushpins}} locaties.
                                    </p>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="inspection?.inspection_status_id === 4">
                                <div>
                                    <p>
                                        {{customerArea.kind}} {{customerArea.name}} heeft {{selectedParts}} punten
                                        geselecteerd voor uitvoering. Van Velsen zal deze werkzaamheden gaan inplannen
                                        en uitvoeren als de opdrachtbevestiging binnen is.
                                    </p>
                                    <p>
                                        Geselecteerde markeringsitems: {{selectedParts}} van {{totalParts}}
                                        op {{selectedPushpins}} locaties.
                                    </p>
                                </div>
                                <div class="d-flex">
                                </div>
                            </ng-container>
                            <ng-container *ngIf="inspection?.inspection_status_id === 5">
                                <div>
                                    <p>
                                        Van Velsen is de werkzaamheden voor {{customerArea.kind}} {{customerArea.name}}
                                        aan het uitvoeren.

                                        Uitgevoerde markeringsitems: {{selectedParts}} van {{totalParts}}
                                        op {{selectedPushpins}} locaties.
                                    </p>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="inspection?.inspection_status_id === 6">
                                <div>
                                    <p>
                                        Van Velsen heeft de werkzaamheden voor {{customerArea.kind}} {{customerArea.name}}
                                        voltooid! Er zijn {{selectedParts}} van de {{totalParts}} vakken uitgevoerd.
                                        U kunt de markeringsitems nu goed- of afkeuren.
                                    </p>
                                    <p>
                                        Uitgevoerde markeringsitems: {{selectedParts}} van {{totalParts}}
                                        op {{selectedPushpins}} locaties.
                                    </p>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div *ngIf="AuthorisationService.hasFeature('selectionPriceForSelectedToggle')" class="pb-2">
                        <mat-slide-toggle (change)="linkPricesAndFiltersChange()"
                                          [(ngModel)]="linkPriceCalculationToVisibleFilters" color="primary">
                            Toon prijs voor gefilterde delen
                        </mat-slide-toggle>
                    </div>
                    <div *ngIf="materialSurfaces" class="mb-2">
                        <strong class="condensed">
                            <ng-container *ngIf="!linkPriceCalculationToVisibleFilters">
                                <ng-container *ngIf="inspection.inspection_status.id === 1">
                                    Totaal tot nu toe geschouwd
                                </ng-container>
                                <ng-container *ngIf="inspection.inspection_status.id === 2">
                                    Selectie voor klant
                                </ng-container>
                                <ng-container *ngIf="inspection.inspection_status.id > 2">
                                    Prijsberekening
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="linkPriceCalculationToVisibleFilters">
                                Totaal gefilterde delen
                            </ng-container>
                        </strong>
                        <table class="price-table w-100">
                            <tr class="border-bottom">
                                <th>
                                    Item
                                </th>
                                <th class="text-right" width="75">
                                    Hoeveelheid
                                </th>
                                <th class="text-right" width="80">
                                    Prijs
                                </th>
                            </tr>
                            <tr *ngIf="loadingPrices">
                                <td colspan="3">
                                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                                </td>
                            </tr>
                            <ng-container *ngFor="let materialPricesGroup of materialPricesGroups">
                                <tr>
                                    <th colspan="3">
                                        {{materialPricesGroup.group}}
                                    </th>
                                </tr>
                                <ng-container *ngFor="let materialPrice of materialPricesGroup.materialPrices">
                                    <tr *ngIf="materialSurfaces.get(materialPrice.id) > 0">
                                        <td>
                                            <div class="ellipsis">
                                                {{materialPrice.material.name}}
                                                {{(materialPrice.paint_color_id < 2) ? '' : materialPrice.paint_color.name}}
                                            </div>
                                        </td>
                                        <td class="price-cell">{{materialSurfaces.get(materialPrice.id) | number:'1.0-2'}}<span
                                            class="gray">m<sup *ngIf="materialPrice.material_id != 8">2</sup></span>
                                        </td>
                                        <td class="price-cell">
                                    <span
                                        class="eu">€</span> {{materialSurfaces.get(materialPrice.id) * materialPrice.price | number:'1.2-2'}}
                                        </td>
                                    </tr>
                                </ng-container>
                                <ng-container *ngFor="let decorationPrice of materialPricesGroup.specificPriceDecoration; let i = index">
                                    <tr>
                                        <td>
                                            <div class="ellipsis"
                                                 title="{{decorationPrice.decoration.name}}  {{decorationPrice.paint_color_id == 1 ? '' : decorationPrice.paint_color.name}}">
                                                {{decorationPrice.decoration.name}} {{decorationPrice.paint_color_id == 1 ? '' : decorationPrice.paint_color.name}}
                                            </div>
                                        </td>
                                        <td class="price-cell">
                                            {{specificPriceDecorationLength.get(decorationPrice.id) | number:'1.0-2'}}
                                            <span class="gray">
                                                {{decorationPrice.decoration.specify_length ? 'm' : decorationPrice.decoration?.surface ? 'st' : 'm2'}}
                                            </span>
                                        </td>
                                        <td class="price-cell">
                                            <span class="eu">€</span>
                                            {{specificPriceDecorationLength.get(decorationPrice.id) * decorationPrice.price | number:'1.2-2'}}
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>


                            <ng-container *ngIf="extraCostsList && extraCostsList.length !== 0">
                                <tr class="total">
                                    <th>
                                        Subtotaal:
                                    </th>
                                    <th class="price-cell total" colspan="2">
                                        € {{subTotalPrice | number:'1.2-2'}}
                                    </th>
                                </tr>
                                <tr>
                                    <td colspan="3">&nbsp;</td>
                                </tr>
                                <tr class="border-bottom">
                                    <th>
                                        Extra kosten
                                    </th>
                                    <th class="text-right" width="75">
                                        Aantal
                                    </th>
                                    <th class="text-right" width="70">
                                        Prijs
                                    </th>
                                </tr>
                                <ng-container *ngFor="let extraCost of extraCostsList; let i = $index">
                                    <tr (click)="editExtraCost(extraCost)" [class.curpoint]="AuthorisationService.hasFeature('extraCostSave')"
                                        class="extra-cost-item">
                                        <td>
                                            <div class="ellipsis" title="{{extraCost.extra_cost_type.name}}">
                                                {{extraCost.extra_cost_type.name}}
                                            </div>
                                        </td>
                                        <td class="text-right">
                                            {{extraCost.number | number :'1.0-2'}} <span
                                            class="gray"> {{extraCost.extra_cost_type.unit}}</span>
                                        </td>
                                        <td class="price-cell">
                                            <span class="eu">€</span>
                                            {{extraCost.number * extraCostsPricesMap[extraCost.extra_cost_type_id] | number:'1.2-2'}}
                                        </td>
                                    </tr>
                                    <tr (click)="editExtraCost(extraCost)" *ngIf="extraCost.comment"
                                        [class.curpoint]="AuthorisationService.hasFeature('extraCostSave')" class="extra-cost-item-comment">
                                        <td colspan="3">
                                            <i>&nbsp;&nbsp;&nbsp;{{extraCost.comment}}</i>
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="tailChargeList && tailChargeList.length !== 0 && !linkPriceCalculationToVisibleFilters">
                                <tr class="total">
                                    <th>
                                        Subtotaal:
                                    </th>
                                    <th class="price-cell total" colspan="2">
                                        € {{subTotalPriceExtraCost | number:'1.2-2'}}
                                    </th>
                                </tr>
                                <tr>
                                    <td colspan="3">&nbsp;</td>
                                </tr>
                                <tr class="border-bottom">
                                    <th>
                                        Staartkosten
                                    </th>
                                    <th class="text-right" width="75">
                                        percentage
                                    </th>
                                    <th class="text-right" width="70">
                                        Prijs
                                    </th>
                                </tr>
                                <ng-container *ngFor="let tailCharge of tailChargeList; let i = $index">
                                    <tr (click)="editTailCharge(tailCharge)" [class.curpoint]="AuthorisationService.hasFeature('saveTailCharges')"
                                        class="extra-cost-item">
                                        <td>
                                            <div class="ellipsis" title="{{tailCharge.comment}}">
                                                {{tailCharge.comment}}
                                            </div>
                                        </td>
                                        <td class="text-right">
                                            {{tailCharge.number * 100 | number:'1.3-3'}} <span
                                            class="gray">%</span>
                                        </td>
                                        <td class="price-cell">
                                            <span class="eu">€</span>
                                            {{tailCharge.number * subTotalPriceExtraCost | number:'1.2-2'}}
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                            <tr class="total">
                                <th>
                                    Totaal:
                                </th>
                                <th class="price-cell total" colspan="2" *ngIf="!linkPriceCalculationToVisibleFilters">
                                    € {{totalPrice | number:'1.2-2'}}
                                </th>
                                <th class="price-cell total" colspan="2" *ngIf="linkPriceCalculationToVisibleFilters">
                                    € {{totalPrice | number:'1.2-2'}}
                                </th>
                            </tr>
                            <tr *ngIf="inspection.inspection_status.selection_allowed && !linkPriceCalculationToVisibleFilters">
                                <td class="font-italic" colspan="2">
                                    Totaal in schouwronde:
                                </td>
                                <td class="font-italic text-right">
                                    <span class="eu">€</span> {{totalPriceAllParts | number:'1.2-2'}}
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div>
                        <div class="extra-costs-list">
                            <table class="price-table w-100">

                            </table>
                        </div>
                    </div>
                    <button (click)="setPaid()"
                            *ngIf="AuthorisationService.hasFeature('setPaidState') && inspection?.inspection_status_id === 7"
                            class="w-100 mb-1"
                            mat-stroked-button>
                        <i class="fa fa-money-check-alt"></i> Goedgekeurd > betaald ({{partCount}})
                        <i *ngIf="settingPaid" class="fa fa-spinner fa-spin"></i>
                    </button>
                    <button [routerLink]="['/prices', {customerAreaIds: customerArea.id,inspectionIds: inspection.id}]"
                            *ngIf="AuthorisationService.hasFeature('pricesSave')"
                            class="w-100 mb-1"
                            mat-stroked-button>
                        <i class="fa fa-euro"></i>
                        Prijzen aanpassen
                    </button>
                    <button (click)="downloadQuotation()"
                            matTooltip="Punten met de status: {{statusIncludedInPdf.get(inspection?.inspection_status_id)}}"
                            *ngIf="AuthorisationService.hasFeature('quotationPdf')"
                            class="w-100 mb-1"
                            mat-stroked-button>
                        <ng-container *ngIf="!generatingQuotation">
                            <i class="fa fa-download"></i> Prijsspecificatie downloaden
                        </ng-container>
                        <ng-container *ngIf="generatingQuotation">
                            <i class="fa fa-spinner fa-spin"></i> Specificatie wordt gemaakt...
                        </ng-container>
                    </button>
                    <button (click)="showChanges()"
                            *ngIf="AuthorisationService.hasFeature('quotationPdf') && inspection?.inspection_status_id > 3 && inspection?.inspection_type_id === 1"
                            class="w-100 mb-1"
                            mat-stroked-button>
                        Toon meerwerk t.o.v. offerte
                    </button>
                    <ng-container *ngIf="!linkPriceCalculationToVisibleFilters && inspection?.inspection_type_id === 1">
                        <ng-container
                            *ngIf="!inspection.lock && (inspection.inspection_status.selection_allowed || (AuthorisationService.hasFeature('pushpinPartReleaseConsumer') && inspection?.inspection_status_id === 2))">
                            <button (click)="addVisiblePoints()" class="w-100 mb-1" color="primary" mat-stroked-button [disabled]="addingPoints || removingPoints">
                                <ng-container *ngIf="addingPoints">
                                    Items ({{partCount}}) aan het toevoegen <i class="fas fa-circle-notch fa-spin"></i>
                                </ng-container>
                                <ng-container *ngIf="!addingPoints">
                                    Items ({{partCount}}) van kaart toevoegen
                                </ng-container>
                            </button>
                            <button (click)="removeAllPoints()" class="w-100 mb-1" mat-stroked-button [disabled]="addingPoints || removingPoints">
                                <ng-container *ngIf="removingPoints">
                                    Selectie aan het wissen... <i class="fas fa-circle-notch fa-spin"></i>
                                </ng-container>
                                <ng-container *ngIf="!removingPoints">
                                    Alle punten uit selectie verwijderen
                                </ng-container>
                            </button>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </mat-tab>
        <mat-tab *ngIf="inspection" label="Status">
            <div class="actions-container">
                <div class="selection">
                    <div *ngIf="customerArea && AuthorisationService.hasFeature('inspectionSave')">
                        <app-choose-inspection (inspectionChanged)="inspectionChange()" [customerArea]="customerArea" [inspection]="inspection"></app-choose-inspection>
                    </div>
                </div>
                <div class="comments">
                    <div *ngIf="!inspectionStatusLog" class="comments-loading">
                        <i class="fas fa-circle-notch fa-spin"></i><br><br>
                        Opmerkingen aan het laden...
                    </div>
                    <div *ngIf="inspectionStatusLog" class="comment-line" id="comment-line">
                        <div *ngFor="let log of inspectionStatusLog" class="comment-item">
                            <div class="border-bottom">
                                <b>{{log.user?.name}}</b>
                                <div class="date">
                                    {{log.updated_at | date : 'd MMM yyyy, H:mm'}}
                                </div>
                            </div>
                            <ng-container *ngIf="!log.attachment">
                                {{log.comment}}
                            </ng-container>
                            <div (click)="downloadAttachment(log)" *ngIf="log.attachment" class="curpoint">
                                <i class="fas fa-file-alt"></i> {{log.comment}}
                            </div>
                        </div>
                    </div>
                    <div class="comment-input">
                        <mat-form-field appearance="fill">
                            <mat-label>Plaats een opmerking</mat-label>
                            <textarea [(ngModel)]="commentBoxValue"
                                      [disabled]="savingComment"
                                      [maxLength]="500"
                                      matInput></textarea>

                        </mat-form-field>
                        <div class="comment-actions">
                            <button (click)="addComment()"
                                    [disabled]="savingComment || !commentBoxValue"
                                    color="primary"
                                    mat-icon-button
                                    matTooltip="Plaats opmerking"
                                    matTooltipPosition="right">
                                <i class="fas fa-comment"></i>
                            </button>
                            <button (click)="uploadInput.nativeElement.click()"
                                    [disabled]="uploading"
                                    color="primary"
                                    mat-icon-button
                                    matTooltip="Upload een bestand"
                                    matTooltipPosition="right"
                                    style="padding: 0">

                                <i *ngIf="!uploading" class="fas fa-upload"></i>
                                <i *ngIf="uploading" class="fas fa-circle-notch fa-spin"></i>
                                <input #uploadInput (change)="uploadFile($event)" *ngIf="!uploading" hidden type="file">

                            </button>
                        </div>
                    </div>
                </div>
                <div class="actions">
                    <ng-container *ngIf="inspection?.inspection_status_id === 1 && AuthorisationService.hasFeature('statusFinishInspect')">
                        <button (click)="statusChange(2)" class="w-100" color="primary" mat-raised-button [disabled]="inspection?.lock">
                            <i class="fas fa-check fa-4x pt-2"></i><br>
                            <div class="icon-button-text">
                                Schouwen afronden
                            </div>
                        </button>
                    </ng-container>
                    <ng-container *ngIf="inspection?.inspection_status_id === 2 && AuthorisationService.hasFeature('statusReleaseInspect')">
                        <div class="d-flex">
                            <button (click)="statusChange(1)" class="w-100 mb-1 mr-1" mat-raised-button [disabled]="inspection?.lock">
                                <i class="fas fa-road fa-4x pt-2"></i><br>
                                <div class="icon-button-text">
                                    Verder schouwen
                                </div>
                            </button>
                            <button (click)="statusChange(3)" class="w-100 mb-1 ml-1" color="primary" mat-raised-button [disabled]="inspection?.lock">
                                <i class="fas fa-city fa-4x pt-2"></i><br>
                                <div class="icon-button-text">
                                    Schouw vrijgeven<br>voor klant
                                </div>
                            </button>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="inspection?.inspection_status_id === 3">
                        <div class="d-flex">
                            <button (click)="statusChange(2)"
                                    *ngIf="AuthorisationService.hasAnyFeature(['statusRequestRecontrol', 'statusRecontrol'])"
                                    class="w-100 mb-1 mr-1"
                                    mat-raised-button [disabled]="inspection?.lock">
                                <i class="fas fa-undo-alt fa-4x pt-2"></i><br>
                                <div *ngIf="AuthorisationService.hasFeature('statusRequestRecontrol')" class="icon-button-text">
                                    Hercontrole <br>aanvragen
                                </div>
                                <div *ngIf="AuthorisationService.hasFeature('statusRecontrol')" class="icon-button-text">
                                    Controle opnieuw <br>starten
                                </div>
                            </button>
                            <button (click)="statusChange(4, true)"
                                    *ngIf="AuthorisationService.hasAnyFeature(['statusGiveOrder' , 'statusGiveOrderOverride'])"
                                    class="w-100 mb-1 ml-1"
                                    color="primary" mat-raised-button [disabled]="inspection?.lock">
                                <i class="fas fa-snowplow fa-4x pt-2"></i><br>
                                <div class="icon-button-text">
                                    Geselecteerde <br>markeringsitems <br> in opdracht geven
                                </div>
                            </button>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="inspection?.inspection_status_id === 4">
                        <div class="d-flex">
                            <button (click)="statusChange(1)" *ngIf="AuthorisationService.hasFeature('statusRestartInspect')" class="w-100 mr-1 mb-1 "
                                    mat-raised-button [disabled]="inspection?.lock">
                                <i class="fas fa-undo-alt fa-4x pt-2"></i><br>
                                <div class="icon-button-text">
                                    Schouw opnieuw <br>starten
                                </div>
                            </button>
                            <button (click)="statusChange(5)" *ngIf="AuthorisationService.hasFeature('statusStartExecution')" class="w-100 mb-1 ml-1"
                                    color="primary" mat-raised-button [disabled]="inspection?.lock">
                                <img src="./assets/images/j16_white.svg" style="max-width: 56px;"><br>
                                <div class="icon-button-text">
                                    Uitvoering starten
                                </div>
                            </button>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="inspection?.inspection_status_id === 5">
                        <button (click)="statusChange(6)"
                                *ngIf="AuthorisationService.hasFeature('statusFinishExecution')" class="w-100 mb-1"
                                color="primary" mat-raised-button [disabled]="inspection?.lock">
                            <i class="fas fa-check fa-4x pt-2"></i><br>
                            <div class="icon-button-text">
                                Uitvoering afronden
                            </div>
                        </button>
                    </ng-container>
                    <ng-container *ngIf="inspection?.inspection_status_id === 6">
                        <div class="d-flex">
                            <button (click)="statusChange(5)" *ngIf="AuthorisationService.hasFeature('statusBackToExecution')" class="w-100 mb-1 mr-1"
                                    mat-raised-button [disabled]="inspection?.lock">
                                <i class="fas fa-undo-alt fa-4x pt-2"></i><br>
                                <div class="icon-button-text">
                                    Terug naar <br>'In uitvoering'
                                </div>
                            </button>
                            <button (click)="statusChange(7)" *ngIf="AuthorisationService.hasFeature('statusExecutionReady')" class="w-100 mb-1 ml-1"
                                    color="primary" mat-raised-button [disabled]="inspection?.lock">
                                <i class="fas fa-check fa-4x pt-2"></i><br>
                                <div class="icon-button-text">
                                    Klaar met <br>uitvoering
                                </div>
                            </button>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="inspection?.inspection_status_id === 7">
                        <div class="d-flex">
                            <button (click)="statusChange(1)" *ngIf="AuthorisationService.hasFeature('statusStartNewInspect')" class="w-100 mb-1 ml-1"
                                    color="primary" mat-raised-button [disabled]="inspection?.lock">
                                <i class="fas fa-road fa-4x pt-2"></i><br>
                                <div class="icon-button-text">
                                    Nieuwe schouw<br>starten
                                </div>
                            </button>
                            <button (click)="acceptWork()" *ngIf="AuthorisationService.hasFeature('statusStartNewInspect')" class="w-100 mb-1 ml-1"
                                    color="primary" mat-raised-button [disabled]="inspection?.lock">
                                <i class="fas fa-check fa-4x pt-2"></i><br>
                                <div class="icon-button-text">
                                    Uitgevoerd werk<br>goedkeuren
                                </div>
                            </button>
                        </div>
                    </ng-container>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
