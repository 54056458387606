<app-vvr-icon *ngIf="iconToMap" [dark]="false" [full]="false" [overlay]="true" [shadow]="false" (iconClick)="onNoClick()" link=""></app-vvr-icon>
<app-vvr-icon *ngIf="!iconToMap" [dark]="false" [full]="false" [overlay]="true" [shadow]="false"></app-vvr-icon>
<div class="topbar">
    <div class="detail-nav">
        <div (click)="openSideBarAction($event)" class="fab small" id="btn-AllParts"
             matTooltip="Toon delen"
             matTooltipPosition="below">
            <i class="fas fa-clipboard-list"></i>
        </div>
        <div (click)="onNoClick()" class="fab small" id="btn-BackToMap"
             *ngIf="!iconToMap"
             matTooltip="Terug naar kaart"
             matTooltipPosition="below">
            <i [class.fa-list-ul]="backToList"
               [class.fa-map]="!backToList"
               class="fas"></i>
        </div>
        <div (click)="backToPushpinLocation()" class="fab small"
             matTooltip="Open locatie op kaart"
             matTooltipPosition="below">
            <i class="fas fa-search-location"></i>
        </div>
        <div (click)="openNavi()" class="fab small"
             matTooltip="Open navigatie"
             matTooltipPosition="below">
            <i class="fas fa-map-marked"></i>
        </div>
    </div>
    <div class="spacer"></div>
    <div *ngIf="activePushpinPart" class="part-tools">
        <div (click)="copy(activePushpinPart)" class="fab small" id="btn-Copy"
             matTooltip="Kopiëer dit deel"
             matTooltipPosition="below">
            <i class="fas fa-copy"></i>
        </div>
        <div (click)="openPushpinPartLog(activePushpinPart)" *ngIf="AuthorisationService.hasFeature('pushpinPartLog') && activePushpinPart?.id" class="fab small" id="btn-History"
             matTooltip="Wijzigingshistorie"
             matTooltipPosition="below">
            <i class="fas fa-history"></i>
        </div>
        <div (click)="removePushpinPart(activePushpinPart)"
             *ngIf="!activePushpinPart.deleted_at && AuthorisationService.hasFeature('pushpinDeleteAny') || (activePushpinPart.status_id === 8 && userId === activePushpinPart.user_id)"
             class="fab small primary"
             id="btn-TrashPart"
             matTooltip="Verwijder deel"
             matTooltipPosition="below">
            <i class="fas fa-trash"></i>
        </div>
        <div (click)="restorePushpinPart(activePushpinPart)"
             *ngIf="activePushpinPart.deleted_at && AuthorisationService.hasFeature('showDeletedPushpins')"
             class="fab small primary"
             id="btn-TrashPartRestore"
             matTooltip="Verwijderd deel terugzetten"
             matTooltipPosition="below">
            <i class="fas fa-trash-restore"></i>
        </div>
    </div>
</div>
<div (click)="openSideBar = false" *ngIf="openSideBar" class="filter-overlay"></div>
<div *ngIf="pushpin" class="detail">
    <div [class.opened]="openSideBar" class="detail-sidebar">
        <div class="detail-sidebar-scroller">
            <div (click)="openPushpinPart(null)"
                 [class.active]="!activePushpinPart"
                 class="part-sidebar curpoint">
                <div class="part-sidebar-title">
                    <h2 class="h5 condensed">Locatiegegevens</h2>
                </div>
                <i class="fas fa-angle-right"></i>
            </div>
            <div (click)="openPushpinPart(pushpinPart)"
                 *ngFor="let pushpinPart of pushpin.pushpin_parts | filter : inspection?.id : 'inspection_id'"
                 [class.active]="activePushpinPart == pushpinPart"
                 [class.deleted]="pushpinPart.deleted_at"
                 [class.invalid]="pushpinPart['invalid']"
                 class="part-sidebar curpoint">
                <i class="fas fa-trash"></i>
                <div class="part-sidebar-title">
                    <h2 class="h6 condensed">{{pushpinPart.decoration.decoration_group}} <span
                        *ngIf="pushpinPart.decoration.decoration_group == 'Lengtemarkering' && !pushpin.lengthmark">(kort)</span>
                    </h2>
                    <p class="label">
                        {{materialName(pushpinPart.material_id)}}
                        {{pushpinPart.decoration.name}}
                    </p>
                </div>
                <i class="fas fa-angle-right"></i>
            </div>
            <ng-container
                *ngIf="(pushpin.pushpin_parts | filter : inspection?.id : 'inspection_id').length < pushpin.pushpin_parts.length && AuthorisationService.hasFeature('changePushpinPartInspection')">
                <div class="part-sidebar">
                    <button mat-button (click)="showFromOtherInspections = !showFromOtherInspections">
                        {{showFromOtherInspections ? 'Verberg delen uit andere schouwronden' : 'Toon delen uit andere schouwronden'}}
                    </button>
                </div>
                <ng-container *ngIf="showFromOtherInspections">
                    <div (click)="openPushpinPart(pushpinPart)"
                         *ngFor="let pushpinPart of pushpin.pushpin_parts | filter : inspection?.id : 'inspection_id' : true"
                         [class.active]="activePushpinPart == pushpinPart"
                         [class.deleted]="pushpinPart.deleted_at"
                         [class.invalid]="pushpinPart['invalid']"
                         class="part-sidebar curpoint">
                        <i class="fas fa-trash"></i>
                        <div class="part-sidebar-title">
                            <h2 class="h6 condensed">{{pushpinPart.decoration.decoration_group}} <span
                                *ngIf="pushpinPart.decoration.decoration_group == 'Lengtemarkering' && !pushpin.lengthmark">(kort)</span>
                            </h2>
                            <p class="label">
                                {{materialName(pushpinPart.material_id)}}
                                {{pushpinPart.decoration.name}}
                            </p>
                        </div>
                        <i class="fas fa-angle-right"></i>
                    </div>
                </ng-container>
            </ng-container>
        </div>
        <div *ngIf="showAddPartButton" class="add-part">
            <button (click)="createNewPushpinPart()" mat-raised-button><i class="fas fa-plus"></i>
                Voeg markeringsitem toe
            </button>
        </div>
    </div>
    <div *ngIf="pushpin" class="detail-container">
        <h1 class="h3 condensed">
            Punaise {{pushpin.number}} {{pushpin.lengthmark ? ' - lengtemarkering' : ''}}
            {{activePushpinPart?.deleted_at ? ' (verwijderd)' : ''}}
        </h1>
        <ng-container *ngIf="pushpin.snappedpoints">
            <p>
                Berekende lengte: {{pushpin.snappedpoints.totalLength | number }}m
            </p>
        </ng-container>
        <div *ngIf="!activePushpinPart">
            <form [formGroup]="form">
                <div class="row">
                    <mat-form-field appearance="fill" class="col-md-9">
                        <mat-label>Straat</mat-label>
                        <input formControlName="street" id="street" matInput name="street" placeholder="Straatnaam"
                               type="text">
                        <mat-error>Dit veld mag maximaal 255 tekens bevatten</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="col-md-3">
                        <mat-label>Huisnummer</mat-label>
                        <input formControlName="housenumber" id="housenumber" matInput name="housenumber"
                               placeholder="Bijv. 12"
                               type="text">
                        <mat-error>Dit veld mag maximaal 20 tekens bevatten</mat-error>
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field appearance="fill" class="col-md-2">
                        <mat-label>Postcode</mat-label>
                        <input formControlName="zipcode" id="zipcode" matInput name="zipcode"
                               placeholder="Bijv. 1234 AB"
                               type="text">
                        <mat-error>Dit veld mag maximaal 7 tekens bevatten</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="col-md-6">
                        <mat-label>Plaats</mat-label>
                        <input formControlName="place" id="place" matInput name="place" placeholder="Plaatsnaam"
                               type="text">
                        <mat-error>Dit veld mag maximaal 255 tekens bevatten</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="col-md-4">
                        <mat-label>Referentie</mat-label>
                        <input formControlName="reference" id="reference" matInput name="reference" placeholder="Referenctie"
                               type="text">
                        <mat-error>Dit veld mag maximaal 255 tekens bevatten</mat-error>
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field appearance="fill" class="col-md-12">
                        <mat-label>Beschrijving</mat-label>
                        <textarea cdkAutosizeMinRows="4" cdkTextareaAutosize formControlName="description"
                                  id="description" matInput
                                  name="description" placeholder="Voeg hier eventuele notities en opmerkingen toe"
                                  type="text"></textarea>
                        <mat-error>Dit veld mag maximaal 16500 tekens bevatten</mat-error>
                    </mat-form-field>
                </div>

            </form>
            <div *ngIf="showPortraitControls">
                <button (click)="createNewPushpinPart()" class="mr-3" mat-raised-button>
                    <i class="fas fa-plus"></i> Voeg markeringsitem toe
                </button>
                <button (click)="openSideBarAction($event)" mat-raised-button>
                    <i class="fas fa-clipboard-list"></i> Toon delen
                </button>
            </div>
        </div>
        <div *ngIf="activePushpinPart">
            <div class="row">
                <div class="col-12">
                    <app-pushpin-part-detail #pushpinPartDetail
                                             (addForm)="addForm($event, activePushpinPart)"
                                             (setBackbuttonAction)="setBackbuttonAction()"
                                             (takePicture)="takePicture()"
                                             [customerArea]="customerArea"
                                             [inspection]="inspection"
                                             [pushpinPart]="activePushpinPart"
                                             [formMap]="forms"
                                             [pushpin]="pushpin"
                                             [uploadDrag]="dragOver"></app-pushpin-part-detail>
                </div>
            </div>
        </div>
        <div *ngIf="!activePushpinPart" mat-dialog-actions>
            <button *ngIf="pushpin.end_lng" mat-raised-button [matMenuTriggerFor]="menu">Corrigeer Lengtemarkering
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="correctLengthmark()">Handmatige corrigeren</button>
                <button mat-menu-item (click)="redoBingSnappedPoints()">Berekening met gereden route</button>
                <button mat-menu-item (click)="redoBingSnappedPoints(false, false)">Berekening <b>zonder</b> gereden route</button>
                <button mat-menu-item (click)="redoBingSnappedPoints(true)">Berekening als fiets/wandel pad</button>
                <button mat-menu-item (click)="redoBingSnappedPoints(true, false)">Berekening als fiets/wandel pad <b>zonder</b> gereden route</button>
            </mat-menu>
        </div>
        <div class="controls-bottom" *ngIf="AuthorisationService.hasFeature('pushpinSave')">
            <label *ngIf="!isCordova"
                   matTooltip="Upload afbeeldingen"
                   matTooltipPosition="before">
                <input
                    (change)="uploadFile($event)" hidden multiple type="file">
                <div class="fab big">
                    <i *ngIf="!uploading" class="fas fa-camera"></i>
                    <i *ngIf="uploading" class="fas fa-circle-notch fa-spin"></i>
                </div>
            </label>
            <div (click)="takePicture()"
                 *ngIf="activePushpinPart && isCordova" class="fab big"
                 id="btn-AddImage"
                 matTooltip="Camera"
                 matTooltipPosition="before">
                <i class="fas fa-camera"></i>
            </div>
            <div (click)="save()" *ngIf="showSave" [class.disabled]="saving || activePushpinPart?.inspection?.lock" class="fab big primary" id="btn-Save"
                 matTooltip="Opslaan"
                 matTooltipPosition="before">
                <i *ngIf="!saving" class="fas fa-save"></i>
                <i *ngIf="saving" class="fas fa-circle-notch fa-spin"></i>
            </div>
        </div>
    </div>
</div>
<div class="version-info">{{cordovaVersion}}</div>
<app-loading *ngIf="loading" text='Momentje, de punaise wordt geladen...'></app-loading>
