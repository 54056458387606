import {Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {CustomerAreaService} from '../../services/consumer-area/customer-area.service';
import {AngularBingMapsComponent, BingPushpin, MapLocation, Polygon} from './angular-bing-maps/angular-bing-maps.component';
import {Region} from './angular-bing-maps/region.class';
import {ActivatedRoute, Router} from '@angular/router';
import {debounceTime, first, map, switchMap} from 'rxjs/operators';
import {CustomerArea} from '../../classes/customerarea.class';
import {PushpinService} from '../../services/pushpin/pushpin.service';
import {Settings} from '../../settings.class';
import {Pushpin} from '../../classes/pushpin.class';
import {Observable, of} from 'rxjs';
import {PushpinFilterComponent} from '../pushpin-filter/pushpin-filter.component';
import {MatDialog} from '@angular/material/dialog';
import {CreatePushpinDialogComponent} from '../../pushpin-detail/pushpin-type-selection-dialog/create-pushpin-dialog.component';
import {StatusService} from '../../services/status/status.service';
import {MaterialService} from '../../services/material/material.service';
import {Material} from '../../classes/material.class';
import {DecorationsService} from '../../services/decorations/decorations.service';
import {Decoration} from '../../classes/decoration.class';
import {Status} from '../../classes/status.class';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {CordovaService} from '../../cordova.service';
import {ManualAreasComponent} from './manual-areas/manual-areas.component';
import {ManualArea} from '../../classes/manualarea.class';
import {PushpinFilter} from '../../pushpin-filter';
import {PushpinSelectionComponent} from '../pushpin-selection/pushpin-selection.component';
import {LocalStorage} from '../../storage.class';
import {MatSnackBar} from '@angular/material/snack-bar';
import {LocationService} from '../../pushpin-detail/location-selector-dialog/location.service';
import {FormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatInput} from '@angular/material/input';
import {CodaltComponent} from '../../codalt.component';
import {Inspection} from '../../classes/inspection';
import {PushpinMoveComponent} from '../pushpin-move/pushpin-move.component';
import {formatNumber} from '@angular/common';

declare var cordova;

@Component({
    selector: 'app-pushpin-map',
    templateUrl: './pushpin-map.component.html',
    styleUrls: ['./pushpin-map.component.scss']
})
export class PushpinMapComponent extends CodaltComponent implements OnInit, OnDestroy {

    @Input() nearby: boolean;

    @ViewChild('map', {static: true})
    public mapComponent: AngularBingMapsComponent;
    @Input()
    filterComponent: PushpinFilterComponent;
    @Input()
    selectionComponent: PushpinSelectionComponent;
    @Input()
    manualAreasComponent: ManualAreasComponent;
    @Input()
    pushpinMoveComponent: PushpinMoveComponent;
    @Output()
    pushpinCreated = new EventEmitter<boolean>();
    @Output()
    addExtraCost = new EventEmitter<void>();
    @Output()
    addTailCharge = new EventEmitter<void>();
    @Output()
    chooseInspection = new EventEmitter<void>();
    addressSearch: Observable<any[]> = null;
    addressAutocomplete = new FormControl();
    @Input()
    view = 'map';
    isCordova = false;
    showPriceSelectionBar: boolean;
    pushpins: Pushpin[] = [];
    mapCenter = null;
    mapZoom;
    mapStyleRoad = true;
    lengthMarkDraft?: Pushpin;
    customerArea: CustomerArea;
    inspection: Inspection;
    validLength = false;
    selectedPushpins: Pushpin[] = [];
    materials: Material[];
    statuses: Status[];
    decorations: Decoration[];
    selectedPosition: {
        bottom, left, maxHeight, right, top
    };
    showAddPinButton = true;
    isSearching = false;
    @ViewChild('searchInput')
    private searchInput: MatInput;
    private prevView: string;
    private townRegion: Region;
    private initialViewAsParam = false;
    private latestUpdatedAt = -1;
    private filter: PushpinFilter;
    private deviceHasInternet = true;
    private areaSnackbarRef;

    constructor(private customerAreaService: CustomerAreaService,
                private activatedRoute: ActivatedRoute,
                private activeRoute: ActivatedRoute,
                private pushpinService: PushpinService,
                private router: Router,
                private materialService: MaterialService,
                private decorationsService: DecorationsService,
                private ngZone: NgZone,
                private dialog: MatDialog,
                private confirmDialogService: ConfirmDialogService,
                private statusService: StatusService,
                private matSnackbar: MatSnackBar,
                private locationService: LocationService,
                private cordovaService: CordovaService) {
        super();
        this.filter = Settings.pushpinFilter;
    }

    coordinatesChange(lat, lng, zoom) {
        this.mapZoom = zoom;
        this.mapComponent.setCenter({
            lat: lat,
            lng: lng
        });
    }

    back() {
        this.customerAreaService.getList().then(customerAreas => {
            if (customerAreas.length < 2) {
                this.router.navigate(['/']);
            } else {
                this.router.navigate(['/customer-selection']);
            }
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        if (this.areaSnackbarRef) {
            this.areaSnackbarRef.dismiss();
        }
    }

    clearHecto() {
        this.mapComponent.clearAllHectoPins(true);
        this.addressAutocomplete.reset();
    }

    openAddressSearch(event) {
        this.isSearching = !this.isSearching;
        if (this.isSearching) {
            setTimeout(() => {
                this.searchInput.value = '';
                this.searchInput.focus();
            }, 100);
        }
    }

    goToAddress(val: MatAutocompleteSelectedEvent) {
        const coords = val.option.value;
        this.mapComponent.setCenter(new MapLocation({lat: coords['lat'], lng: coords['lng']}));
        this.mapComponent.setZoom(18);
        this.isSearching = false;
    }

    showAddress(value: any) {
        return value ? value['name'] : '';
    }

    ngOnInit() {
        this.cordovaService.setBackbuttonAction(() => {
            this.router.navigate(['/customer-selection']);
        });
        this.isCordova = typeof cordova !== 'undefined';

        this.subscriptions.add(this.cordovaService.deviceHasInternet.subscribe((hasInternet) => {
            this.deviceHasInternet = hasInternet;
        }));
        this.manualAreasComponent.mapComponent = this.mapComponent;
        this.pushpinMoveComponent.mapComponent = this.mapComponent;

        this.materialService.getMaterials().then(materials => {
            this.materials = materials;
        });
        this.decorationsService.getList().then(decorations => {
            this.decorations = decorations;
        });
        this.statusService.getStatuses().then(statuses => {
            this.statuses = statuses;
        });

        this.subscriptions.add(this.mapComponent.viewchangestartEvent.subscribe(() => {
            this.selectedPushpins = [];
        }));
        this.subscriptions.add(this.mapComponent.zoomChangedEvent.subscribe((newZoom) => {
            this.mapZoom = newZoom;
            this.updateUrl();
        }));
        let lastArea = null;
        this.subscriptions.add(this.mapComponent.centerChangedEvent.subscribe((newCenter) => {
            this.mapCenter = newCenter;
            this.updateUrl();
            let areaFound = false;
            this.manualAreasComponent?.areas?.forEach((area: ManualArea) => {
                if (area.name) {
                    const manualRegion = new Region(area.isStreet ? area.snappedPoints : area.exteriorRing);
                    if (manualRegion && manualRegion.insideTown(this.mapCenter)) {
                        areaFound = true;
                        if (lastArea !== area.id && this.areaSnackbarRef) {
                            this.areaSnackbarRef.dismiss();
                            this.areaSnackbarRef = null;
                        }
                        if (!this.areaSnackbarRef) {
                            this.areaSnackbarRef = this.matSnackbar.open(area.name, null);
                        }
                        lastArea = area.id;
                    }
                }
            });
            if (!areaFound) {
                if (this.areaSnackbarRef) {
                    this.areaSnackbarRef.dismiss();
                }
                lastArea = null;
                this.areaSnackbarRef = null;
            }
        }));
        this.subscriptions.add(this.mapComponent.validLengthEvent.subscribe((validLength) => {
            this.validLength = validLength;
        }));
        this.subscriptions.add(this.mapComponent.mapRightClickEvent.subscribe(e => {
            if (this.manualAreasComponent.manualAreasMode && this.manualAreasComponent.manualAreaDrawing) {
                this.manualAreasComponent.setPoint(e);
            }
            if (this.pushpinMoveComponent.selectionArea) {
                this.pushpinMoveComponent.setPoint(e);
            }
        }));
        this.subscriptions.add(this.mapComponent.somePointMoveEvent.pipe(debounceTime(300)).subscribe(e => {
            if (this.pushpinMoveComponent.selectionArea) {
                this.pushpinMoveComponent.setPoint();
            }
        }));

        this.subscriptions.add(this.mapComponent.pushpinDbClickEvent.subscribe(e => {
            if (this.manualAreasComponent.manualAreasMode && this.manualAreasComponent.manualAreaDrawing) {
                this.manualAreasComponent.redrawCurrentEditingArea();
            }
        }));

        this.subscriptions.add(this.mapComponent.mapClickEvent.subscribe((event) => {
            this.isSearching = false;
            const lat = event.location.latitude;
            const lng = event.location.longitude;
            this.selectedPushpins = [];

            /*
                Calculate ratio between the pushpins on the map, when pushpins are close
                together show a detail preview box with those pushpin details
            */

            const latitudeRanges = {
                20: 0.00003,
                19: 0.00005,
                18: 0.00012,
                17: 0.00025,
                16: 0.0006,
                15: 0.0012,
                14: 0.0025,
                13: 0.005,
                12: 0.012,
                11: 0.024,
                10: 0.050,
                9: 0.09
            };
            const longitudeRanges = {
                20: 0.00005,
                19: 0.00008,
                18: 0.0002,
                17: 0.0004,
                16: 0.001,
                15: 0.002,
                14: 0.004,
                13: 0.008,
                12: 0.02,
                11: 0.04,
                10: 0.08,
                9: 0.15
            };

            const latitudeRange = latitudeRanges[Math.round(this.mapZoom)];
            const longitudeRange = longitudeRanges[Math.round(this.mapZoom)];
            this.pushpins.forEach((pushpin) => {
                let pushpinInRange = false;
                if (pushpin.lengthmark) {
                    if (pushpin.snappedpoints['snappedPoints'] !== 'undefined') {
                        const points = pushpin.snappedpoints['snappedPoints'];

                        points.forEach((point) => {
                            if (!pushpinInRange) {
                                let calculatedLatitudeDifference = ((point.latitude - lat));
                                let calculatedLongitudeDifference = ((point.longitude - lng));

                                calculatedLatitudeDifference = +Math.abs(calculatedLatitudeDifference);
                                calculatedLongitudeDifference = +Math.abs(calculatedLongitudeDifference);

                                if (calculatedLatitudeDifference < latitudeRange
                                    && calculatedLongitudeDifference < longitudeRange) {
                                    pushpinInRange = true;
                                }
                            }
                        });
                    }
                } else {
                    let calculatedLatitudeDifference = ((pushpin.lat - lat));
                    let calculatedLongitudeDifference = ((pushpin.lng - lng));

                    calculatedLatitudeDifference = +Math.abs(calculatedLatitudeDifference);
                    calculatedLongitudeDifference = +Math.abs(calculatedLongitudeDifference);

                    if (calculatedLatitudeDifference < latitudeRange
                        && calculatedLongitudeDifference < longitudeRange) {
                        pushpinInRange = true;
                    }
                }
                if (pushpinInRange) {
                    if (pushpin.pushpin_parts) {
                        pushpin.pushpin_parts.forEach((part) => {
                            part.material = this.getMaterial(part.material_id);
                            part.status = this.getStatus(part.status_id);
                            part.decoration = this.getDecoration(part.decoration_id);
                        });
                    }

                    pushpin['material_surfaces'] = this.getMaterialSurfaces(pushpin);
                    this.selectedPushpins.push(pushpin);
                }
            });

            let top = event.pageY + 10;
            let left = event.pageX + 15;
            let right = 'auto';
            let bottom = 'auto';
            const screenHeight = document.body.scrollHeight;
            const screenWidth = document.body.scrollWidth;
            let maxHeight = 'calc(100% - ' + (event.pageY + 20) + 'px)';

            if (top > screenHeight / 2) {
                bottom = ((event.pageY - screenHeight) * -1) + 20 + 'px';
                top = 'auto';
                maxHeight = (event.pageY - 30) + 'px';
            } else {
                top = top + 'px';
            }

            if (left > (screenWidth - 450)) {
                left = 'auto';
                right = (screenWidth - event.pageX + 5) + 'px';
            } else {
                left = left + 'px';
            }
            this.ngZone.run(() => {
                this.selectedPosition = {
                    left: left,
                    right: right,
                    top: top,
                    bottom: bottom,
                    maxHeight: maxHeight
                };
            });
        }));

        this.subscriptions.add(this.activatedRoute.params.pipe(first()).subscribe(params => {

            if (params['zoom']) {
                this.mapComponent.setZoom(+params['zoom']);
                this.initialViewAsParam = true;
            }

            if (params['lat'] && params['lng'] && params['lat'] !== 'null') {
                this.mapComponent.setCenter({
                    lat: params['lat'],
                    lng: params['lng']
                });
            } else {
                if (this.townRegion) {
                    setTimeout(() => {
                        this.mapComponent.setCenter(this.townRegion.centroid());
                    }, 1000);
                }
            }
        }));

        this.subscriptions.add(this.activatedRoute.params.subscribe(params => {
            if (params['zoom']) {
                this.mapComponent.setZoom(+params['zoom']);
            }
            if (!this.customerArea && params['view'] === 'map' && this.prevView === 'map-detail') {
                this.initialViewAsParam = false;
            }
            if (params['view'] === 'map' && this.prevView !== params['view']) {
                setTimeout(() => {
                    if (this.selectionComponent) {
                        this.selectionComponent.calculatePrices();
                    }
                });
            }
            this.prevView = params['view'];
        }));

        this.subscriptions.add(this.filterComponent.filterEmitter.subscribe((filter) => {
            this.filter = filter;
            this.latestUpdatedAt = -1;
            if (this.filter.deleted && this.selectionComponent.selectionVisible) {
                this.selectionComponent.toggleSelection();
            }
        }));

        let lastRoad = null;
        this.addressSearch = this.addressAutocomplete.valueChanges.pipe(
            // delay emits
            debounceTime(300),
            // use switch map so as to cancel previous subscribed events, before creating new once
            switchMap(value => {
                if (value && typeof value === 'string' && value?.match(/^([an][0-9]+)(([ ,]{1,3})([0-9]{1,4}[,. ]?[0-9]?))?/i)) {
                    return this.locationService.searchHecto(value).pipe(map((result) => {
                        const res = [];
                        let waynumber = null;
                        result.data.forEach(rs => {
                            waynumber = rs.waynumber;
                            const hectometreFormatted = formatNumber(rs.hectometre / 10, 'nl', '1.1-1');
                            const directionOrLetter = (rs.direction || rs.letter || '');
                            res.push({
                                name: `${rs.waynumber}, ${hectometreFormatted} ${directionOrLetter}`,
                                lat: rs.lat,
                                lng: rs.lng
                            });
                        });
                        if (waynumber !== lastRoad) {
                            lastRoad = waynumber;
                            this.subscriptions.add(this.locationService.getHecto(lastRoad).subscribe(hecto => {
                                this.mapComponent.addHectoPins(hecto.data);
                            }));
                        }
                        return res;
                    }));
                } else if (value && value !== '') {
                    // lookup from Bing Location Service
                    return this.locationService.getAddresses(value).pipe(map((result) => {
                        const bingResults = result['resourceSets'][0]['resources'];
                        const results = [];
                        bingResults.forEach(rs => {
                            results.push({
                                name: rs['name'],
                                lat: rs['point']['coordinates'][0],
                                lng: rs['point']['coordinates'][1]
                            });
                        });
                        return results;
                    }));
                } else {
                    return of(null);
                }
            })
        );
    }

    public openPushpin(pushpin: Pushpin, pushpinPartId = null) {
        if (this.deviceHasInternet) {
            this.router.navigate([
                'pushpins/map-detail',
                pushpin.id,
                this.mapZoom,
                this.mapCenter.lat,
                this.mapCenter.lng,
                {pushpinPartId: pushpinPartId, inspection: this.inspection?.id}
            ]);
        } else {
            this.confirmDialogService.confirm(
                'Geen Internet',
                'Het apparaat heeft momenteel geen internetverbinding en de punaise kan daarom niet worden geopend.',
                'Oké',
                null).then(() => {

            }, () => {

            });
        }
    }

    public setCustomerArea(customerArea: CustomerArea, inspection: Inspection) {
        this.customerArea = customerArea;
        this.inspection = inspection;
        if (customerArea) {
            this.showSelectionBarDecider(inspection);
            this.subscriptions.add(this.filterComponent.filterEmitter.subscribe(filter => {
                if (filter.deleted) {
                    this.showPriceSelectionBar = false;
                } else {
                    this.showSelectionBarDecider(inspection);
                }
            }));
            this.mapComponent.updateCustomerAreaPolygon(new Polygon({
                exteriorRing: this.customerArea.area
            }), this.customerArea);
            let manualAreas = [];
            if (this.inspection?.manual_areas) {
                manualAreas = this.inspection?.manual_areas;
            } else {
                manualAreas = this.customerArea.inspections[0].manual_areas.filter(m => !m.inspection_id);
            }
            const manualPolygons = [];
            manualAreas.forEach(area => {
                manualPolygons.push(area);
            });
            this.mapComponent.updateManualPolygons(manualPolygons);
            this.townRegion = new Region(customerArea.area);
            if (!this.initialViewAsParam) {
                this.centerTown();
            }
            this.manualAreasComponent.customerArea = this.customerArea;
            this.manualAreasComponent.inspection = this.inspection;
            this.manualAreasComponent.areas = manualAreas;

            this.showAddPinButton = this.AuthorisationService.hasFeature('pushpinAlwaysAdd') || this.inspection?.inspection_status.create_allowed;
        } else {
            navigator.geolocation.getCurrentPosition((geolocation) => {
                this.mapComponent.setCenter({
                    lat: geolocation.coords.latitude,
                    lng: geolocation.coords.longitude
                });
            });
        }
    }

    public toggleFilter() {
        this.filterComponent.toggleFilter();
    }

    public toggleMapStyle() {
        setTimeout(() => {
            this.mapStyleRoad = !this.mapStyleRoad;
            this.mapComponent.setMapStyle(this.mapStyleRoad);
            this.updatePushpins();
        }, 250);
    }

    public centerTown() {
        this.mapComponent.setZoom(
            this.townRegion.bestMapView(window.innerWidth, window.innerHeight, 5)
        );
        this.mapComponent.setCenter(this.townRegion.centroid());
    }

    public navigateToPushpin() {
        if (this.inspection?.lock) {
            this.confirmDialogService.confirm(
                'Schouwronde vergrendeld',
                `Je kunt geen punten toevoegen aan deze schouwronde omdat deze is vergrendeld`,
                'Sluiten',
                null).then(() => {
            }, () => {
            });
        } else if (this.inspection) {

            const region = new Region(this.customerArea.area);
            let insideTown = region.insideTown(this.mapCenter);

            if (this.customerArea.geojson) {
                const geopolygons = [];
                this.customerArea?.geojson.features.forEach(feature => {
                    feature.geometry.coordinates.forEach(polygonGroups => {
                        const polygons = polygonGroups.map(polygon =>
                            polygon.map(coord => {
                                return {lat: coord[1], lng: coord[0]};
                            }));
                        geopolygons.push(polygons);
                    });
                });

                insideTown = geopolygons.some(geopolygon => {
                    const outerRing = new Region(geopolygon[0]);

                    if (!outerRing.insideTown(this.mapCenter)) {
                        return false;
                    }

                    return !geopolygon.slice(1).some(hole => new Region(hole).insideTown(this.mapCenter));
                });
            }

            if (!insideTown) {
                this.confirmDialogService.confirm(
                    'Buiten gebiedsgrens',
                    // tslint:disable-next-line:max-line-length
                    `Weet je zeker dat je een punaise aan wilt maken buiten de gebiedsgrens van ${this.customerArea.kind} ${this.customerArea.name}`,
                    'Ja',
                    'Nee').then(() => {
                    this.createPushpin();
                }, () => {
                });
            } else {
                let manualArea = null;
                this.manualAreasComponent.areas.forEach((area: ManualArea) => {
                    if (area.show_notification && !manualArea) {
                        let manualRegion = new Region(area.exteriorRing);
                        if (area.isStreet) {
                            manualRegion = new Region(area.snappedPoints);
                        }
                        if (manualRegion && manualRegion.insideTown(this.mapCenter)) {
                            manualArea = area;
                        }
                    }
                });

                if (manualArea !== null) {
                    this.confirmDialogService.confirm(
                        'Binnen gemarkeerd gebied',
                        `Weet je zeker dat je een punaise aan wilt maken binnen gebied ${manualArea.name}?`,
                        'Ja',
                        'Nee').then(() => {
                        this.createPushpin();
                    }, () => {
                    });
                } else {
                    this.createPushpin();
                }
            }
        } else {
            this.confirmDialogService.confirm(
                'Selecteer eerst een schouwronde',
                `Selecteer eerst in de prijzenbalk (links) een schouwronde om een punt toe te kunnen voegen.`,
                'Sluiten',
                null).then(() => {

            }, () => {
            });
        }
    }

    public centerOnGps() {
        this.mapComponent.centerOnGps();
    }

    public deleteDraftPushpin() {
        const pushpin = this.pushpins.find(p => p.draft);
        if (pushpin?.end_lat) {
            this.confirmDialogService.confirm(
                'Lengtemarkering corrigeren annuleren',
                `Weet je zeker dat je de correctie van de huidige lengtemarkering wilt annuleren?`,
                'Correctie annuleren',
                'Doorgaan'
            ).then(() => {
                this.router.navigate([
                    'pushpins/map-detail',
                    pushpin.id,
                    this.mapZoom,
                    pushpin.end_lat,
                    pushpin.end_lng,
                    {inspection: this.inspection?.id}
                ]);
            }, () => {

            });
        } else {
            this.confirmDialogService.confirm(
                'Lengtemarkering verwijderen',
                `Weet je zeker dat je de huidige lengtemarkering wilt verwijderen?`,
                'Verwijderen',
                'Annuleren'
            ).then(() => {
                this.pushpinService.deletePushpin(pushpin.id, this.customerArea.id).then((result) => {
                    this.pushpinCreated.emit(true);
                });
            }, () => {

            });
        }
    }

    public navigateDraftPushpin() {
        this.router.navigate([
            'pushpins/map-detail',
            this.pushpins.filter(p => p.draft)[0].id,
            this.mapZoom,
            this.mapCenter.lat,
            this.mapCenter.lng,
            {
                inspection: this.inspection?.id,
                walking: this.mapComponent.travelModeWalking,
                useRoute: this.mapComponent.lengthMarkUseRoute
            }
        ]);
    }

    public reloadPushpins(pushpins: Pushpin[]) {
        if (this.customerArea) {
            this.mapComponent.loading = true;
            this.pushpins = pushpins;
            this.lengthMarkDraft = this.pushpins.find(p => p.draft);
            this.updatePushpins();
        } else if (Array.isArray(pushpins)) {
            this.mapComponent.loading = false;
            this.pushpins = pushpins;
            this.updatePushpins();
        }
    }

    private showSelectionBarDecider(inspection: Inspection) {
        this.showPriceSelectionBar = ((this.AuthorisationService.hasFeature('priceList') &&
                inspection?.inspection_status_id > 2) || this.AuthorisationService.hasFeature('showPushpinSelectionBarAlways'))
            && window.matchMedia('(min-width: 1100px)').matches;
    }

    private getMaterial(materialId: number) {
        return this.materials.find(p => p.id === materialId);
    }

    private getMaterialSurfaces(pushpin: Pushpin): {
        id, material, surface
    }[] {
        const materials = [];

        if (pushpin.pushpin_parts) {
            pushpin.pushpin_parts.forEach(part => {
                const deco = this.getDecoration(part.decoration_id);
                let material = materials.filter(p => p.id === part.material_id).pop();
                if (!material) {
                    material = {
                        id: part.material_id,
                        material: part.material.name,
                        surface: 0
                    };
                    materials.push(material);
                }
                if (pushpin.lengthmark) {
                    material.surface += part.amount * part.length;
                } else {
                    if (deco.surface) {
                        if (part.length) {
                            material.surface += part.amount * deco.surface * part.length;
                        } else {
                            material.surface += part.amount * deco.surface;
                        }
                    } else {
                        material.surface += part.amount * part.surface;
                    }
                }
            });
        }

        return materials;
    }

    private getDecoration(decorationId: number) {
        return this.decorations.find(p => p.id === decorationId);
    }

    private getStatus(statusId: number) {
        return this.statuses.find(p => p.id === statusId);
    }

    private updatePushpins() {
        const bingPushpins = [];
        if (this.pushpins) {
            this.pushpins.forEach((pushpin) => {
                const bingPushpin = new BingPushpin();
                bingPushpin.pushpin = pushpin;
                const heaviestStatuses = this.statusService.getHeaviestStatuses(pushpin.pushpin_parts);
                let color1 = 'FF0000';
                let color2 = 'FF0000';
                if (heaviestStatuses.length !== 0) {
                    color1 = heaviestStatuses[0].color;
                    if (heaviestStatuses.length === 2) {
                        color2 = heaviestStatuses[1].color;
                    } else {
                        color2 = heaviestStatuses[0].color;
                    }
                }
                if (pushpin.lengthmark) {
                    bingPushpin.color = color1;
                    bingPushpin.anchorX = 12;
                    bingPushpin.anchorY = 15;

                    if (pushpin.snappedpoints) {
                        bingPushpin.pushpin.lat = pushpin.snappedpoints.snappedPoints[0].latitude;
                        bingPushpin.pushpin.lng = pushpin.snappedpoints.snappedPoints[0].longitude;
                    }

                    bingPushpin.icon = Settings.getLineMarker(
                        pushpin.number,
                        pushpin.number,
                        color1,
                        color2,
                        this.mapStyleRoad
                    );
                } else {
                    bingPushpin.icon = Settings.getMarker(
                        pushpin.number,
                        pushpin.number,
                        color1,
                        color2,
                        this.mapStyleRoad
                    );
                }
                bingPushpins.push(bingPushpin);
            });
        }
        this.mapComponent.updatePushpins(bingPushpins);
        if (this.lengthMarkDraft) {
            this.mapComponent.lengthMarkDraft = true;
        }
    }

    private updateUrl() {
        if ((this.customerArea || this.nearby) &&
            this.mapZoom &&
            this.mapCenter &&
            this.mapCenter.lat &&
            this.view === 'map') {
            this.ngZone.run(() => {
                if (this.view === 'map' && this.activeRoute.snapshot.params['view'] === 'map') {
                    this.router.navigate([
                        `pushpins`,
                        this.activeRoute.snapshot.params['view'],
                        this.customerArea?.id ?? 'nearby',
                        this.mapZoom,
                        this.mapCenter.lat,
                        this.mapCenter.lng,
                        {inspection: this.activeRoute.snapshot.params?.inspection}
                    ]);
                }
            });
        }
    }

    private createPushpin() {
        if (this.deviceHasInternet) {
            const pushpin = new Pushpin();
            pushpin.lat = this.mapCenter.lat;
            pushpin.lng = this.mapCenter.lng;
            pushpin.customer_area_id = this.customerArea.id;
            pushpin.customer_area = this.customerArea;
            const dialogRef = this.dialog.open(CreatePushpinDialogComponent, {
                panelClass: 'pushpin-dialog',
                data: pushpin
            });
            this.cordovaService.setBackbuttonAction(() => {
                dialogRef.close();
            });
            const subscription = dialogRef.afterClosed().subscribe((_pushpin: Pushpin & string) => {
                if (typeof _pushpin === 'object') {
                    if (_pushpin && (_pushpin as Pushpin).lengthmark) {
                        this.pushpinCreated.emit(true);
                        this.mapComponent.distanceDraftLengthMark = 0;
                        LocalStorage.removeSnappedPointsHistory();
                    } else if (_pushpin && !(_pushpin as Pushpin).lengthmark) {
                        this.router.navigate([
                            'pushpins/map-detail',
                            (_pushpin as Pushpin).id,
                            this.mapZoom,
                            this.mapCenter.lat,
                            this.mapCenter.lng,
                            {inspection: this.inspection.id}
                        ]);
                    }
                } else if (_pushpin === 'extraCost') {
                    this.addExtraCost.emit();
                } else if (_pushpin === 'tailCharge') {
                    this.addTailCharge.emit();
                }
                this.cordovaService.setBackbuttonAction(() => {
                    this.router.navigate(['/']);
                });
                subscription.unsubscribe();
            });
        } else {
            this.confirmDialogService.confirm(
                'Geen Internet',
                'Het apparaat heeft momenteel geen internetverbinding. Er kan geen punaise worden aangemaakt.',
                'Oké',
                null).then(() => {

            }, () => {

            });
        }
    }
}
