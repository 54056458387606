import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CustomerArea} from '../../classes/customerarea.class';
import {CustomerAreaService} from '../../services/consumer-area/customer-area.service';
import {CordovaService} from "../../cordova.service";
import {ConfirmDialogService} from "../../services/confirm-dialog-service/confirm-dialog.service";
import {CodaltComponent} from '../../codalt.component';
import {Customer} from '../../classes/customer';

@Component({
    selector: 'app-customer-area-edit',
    templateUrl: './customer-area-edit.component.html',
    styleUrls: ['./customer-area-edit.component.scss']
})
export class CustomerAreaEditComponent extends CodaltComponent implements OnInit {

    saving = false;

    customerArea: CustomerArea;
    customers: Customer[];

    form: FormGroup;
    private groupControl: FormControl;

    constructor(private route: ActivatedRoute,
                private cordovaService: CordovaService,
                private customerAreaService: CustomerAreaService,
                private confirmDialogService: ConfirmDialogService,
                private router: Router) {
        super();
    }

    ngOnInit() {
        this.cordovaService.setBackbuttonAction(() => {
            this.router.navigate(['/customer-areas']);
        });
        this.route.params.subscribe(params => {
            const customerAreaId = +params['customerAreaId'];
            if (customerAreaId) {
                this.getCustomerArea(customerAreaId);
            } else {
                this.customerArea = new CustomerArea();
                this.setFormGroup(this.customerArea);
            }
        });
        this.loadCustomers();
    }

    private loadCustomers() {
        this.subscriptions.add(this.customerAreaService.getCustomers().subscribe(customers => {
            this.customers = customers.data;
        }));
    }

    addTag = (name) => {
        return new Promise((resolve) => {
            const customer = new Customer();
            customer.name = name;
            this.customerAreaService.saveCustomer(customer).subscribe(newTag => {
                this.loadCustomers();
                resolve(newTag.data);
            }, error => {

            });
        });
    };

    addCustomerToArea(customer: Customer) {
        this.form.get('customer_id').setValue(customer.id);
    }

    save() {
        Object.assign(this.customerArea, this.form.value);
        this.saving = true;
        this.customerAreaService.save(this.customerArea).then(user => {
            this.saving = false;
            this.router.navigate([`customer-areas`]);

        }, error => {
            this.saving = false;
            this.confirmDialogService.confirm('Er ging iets fout', error['error']['error']['errormessage']);
        });
    }

    private getCustomerArea(id) {
        this.customerAreaService.getList().then(customerAreas => {
            this.customerArea = customerAreas.find(p => p.id === id);
            this.setFormGroup(this.customerArea);
        });
    }

    private setFormGroup(customerArea: CustomerArea) {
        this.form = new FormGroup({
            name: new FormControl(customerArea.name, [Validators.required, Validators.minLength(3)]),
            kind: new FormControl(customerArea.kind, Validators.required),
            street: new FormControl(customerArea.street),
            number: new FormControl(customerArea.number),
            zip: new FormControl(customerArea.zip),
            city: new FormControl(customerArea.city),
            phone: new FormControl(customerArea.phone),
            notifications: new FormControl(customerArea.notifications),
            customer_id: new FormControl(customerArea.customer_id)
        });
    }

}
