<app-vvr-icon [dark]="false" [full]="false" [overlay]="false" [shadow]="true"></app-vvr-icon>
<div class="container">
    <div class="topbar">
        <div class="title">
            <h1 class="h3 condensed">Klantgebieden</h1>
        </div>
        <div class="controls">
            <div class="fab small" id="btn-Exit" matTooltip="Terug naar overzicht"
                 matTooltipPosition="below"
                 routerLink="/management">
                <i class="fas fa-sign-out-alt"></i>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-striped">
                <thead>
                <tr>
                    <th>
                        Opdrachtgever
                    </th>
                    <th (click)="sortList('name')">
                        Naam <i [class.fa-sort-down]="!desc && currentSort === 'name'"
                                [class.fa-sort-up]="desc && currentSort === 'name'"
                                [class.fa-sort]="currentSort !== 'name'"
                                class="fas"></i>
                    </th>
                    <th (click)="sortList('kind')">
                        Soort <i [class.fa-sort-down]="!desc && currentSort === 'kind'"
                                 [class.fa-sort-up]="desc && currentSort === 'kind'"
                                 [class.fa-sort]="currentSort !== 'kind'"
                                 class="fas"></i>
                    </th>
                    <th *ngIf="AuthorisationService.hasFeature('customerAreaDelete')">

                    </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let customerArea of customerAreas" class="curpoint" routerLink="/customer-areas/{{customerArea.id}}">
                    <td>
                        {{customerArea?.customer?.name}}
                    </td>
                    <td>
                        {{customerArea.name}}
                    </td>
                    <td>
                        {{customerArea.kind}}
                    </td>
                    <td class="pt-0 pb-0" *ngIf="AuthorisationService.hasFeature('customerAreaDelete')">
                        <button (click)="deleteCustomerArea($event, customerArea)" color="warn" mat-icon-button><i class="fa fa-trash"></i></button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row ">
        <div class="col-12">
            <div class="d-flex">
                <button mat-button routerLink="/">Terug</button>
            </div>
        </div>
    </div>
</div>

