<div class="list-container">
    <div class="list-controls">
        <div *ngIf="!showPriceSelectionBar"
             class="controls left">

        </div>
        <div *ngIf="!nearby && showPriceSelectionBar && selectionComponent"
             [class.active]="selectionComponent.selectionVisible"
             class="controls left">
            <div (click)="selectionComponent.toggleSelection()"
                 [class.active]="selectionComponent.selectionVisible" class="fab small"
                 id="btn-selection"
                 matTooltip="Prijs en statusbalk"
                 matTooltipPosition="after">
                <i *ngIf="selectionComponent.selectionVisible" class="fas fa-times"></i>
                <i *ngIf="!selectionComponent.selectionVisible" class="fas fa-shopping-cart"></i>
            </div>
        </div>
        <div class="search-container text-right mr-2">
            <div class="fab small" (click)="tableView = !tableView" matTooltip="{{tableView ? 'Schakel naar lijstweergave' : 'Schakel naar tabelweergave'}}">
                <i *ngIf="tableView" class="fa-duotone fa-solid fa-list"></i>
                <i *ngIf="!tableView" class="fa-duotone fa-solid fa-table-cells"></i>
            </div>
            <mat-form-field class="ml-2" *ngIf="tableView">
                <mat-label>Kolommen</mat-label>
                <mat-select [formControl]="fcTableColumns" multiple>
                    <mat-option *ngFor="let column of columnSelection" [value]="column.column">{{column.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="!isSmallDevice" class="ml-2 mr-2">
                <mat-label>
                    Zoeken
                </mat-label>
                <input (keyup)="search()"
                       [(ngModel)]="searchText"
                       autocomplete="off"
                       matInput
                       matTooltip="Opties: {{showPrice ? '>100 (meer/minder dan 100 euro), ': ''}} 100 (punaise 100) of straat/plaats/figuratie/omschrijving"
                       matTooltipPosition="below">
            </mat-form-field>
            <mat-form-field>
                <mat-label>
                    Sortering
                </mat-label>
                <mat-select (selectionChange)="orderChanged($event)" [(value)]="order">
                    <mat-option value="date">
                        Datum oplopend
                    </mat-option>
                    <mat-option value="-date">
                        Datum aflopend
                    </mat-option>
                    <mat-option *ngIf="showPrice" value="price">
                        Prijs oplopend
                    </mat-option>
                    <mat-option *ngIf="showPrice" value="-price">
                        Prijs aflopend
                    </mat-option>
                    <mat-option value="number">
                        Nummer oplopend
                    </mat-option>
                    <mat-option value="-number">
                        Nummer aflopend
                    </mat-option>
                </mat-select>
            </mat-form-field>

        </div>
        <div class="controls">
            <div (click)="back()" class="fab small" id="btn-Exit"
                 matTooltip="Terug naar overzicht"
                 matTooltipPosition="below">
                <i class="fas fa-sign-out-alt"></i>
            </div>
            <div (click)="excelExport()" *ngIf="!nearby && AuthorisationService.hasFeature('pushpinPartExcelExport') && !isCordova" class="fab small" id="btn-exportexcel"
                 matTooltip="Exporteer delen als Excel bestand"
                 matTooltipPosition="below">
                <i class="fas fa-file-excel"></i>
            </div>
            <div (click)="zipExport()" *ngIf="!nearby && AuthorisationService.hasFeature('pushpinPartZipExport') && !isCordova" class="fab small" id="btn-exportzip"
                 matTooltip="Exporteer fotos in delen als ZIP bestand"
                 matTooltipPosition="below">
                <i *ngIf="!zipExporting" class="fas fa-file-archive"></i>
                <i *ngIf="zipExporting" class="fas fa-spinner fa-spin"></i>
            </div>
            <div class="fab small" id="btn-decorations" matTooltip="Profiel aanpassen"
                 matTooltipPosition="below"
                 routerLink="/profile">
                <i class="fas fa-user"></i>
            </div>
            <div (click)="toggleFilter()" [class.active]="filterComponent.filterVisible"
                 [class.ml-3]="filterComponent.filter.filterCount" [matBadge]="filterComponent.filter.filterCount"
                 class="fab small" id="btn-filters"
                 matBadgePosition="above before"
                 matTooltip="Filters"
                 matTooltipPosition="below">
                <i *ngIf="filterComponent.filterVisible" class="fas fa-times"></i>
                <i *ngIf="!filterComponent.filterVisible" class="fas fa-filter"></i>
            </div>

        </div>
    </div>

    <div [class.disable]="filterComponent.filterVisible" class="controls-bottom">
        <div
            [routerLink]="['/pushpins/map/', customerArea?.id ?? 'nearby', mapZoom, mapCenter?.lat, mapCenter?.lng, {inspection: inspection?.id}]"
            class="fab big"
            id="btn-Mapview"
            matTooltip="Kaartweergave"
            matTooltipPosition="before">
            <i class="fas fa-map"></i>
        </div>
    </div>


    <div class="table-container" *ngIf="showList && tableView">
        <div class="header">
            <table class="table-header" [style.min-width.px]="tableWidth">
                <tr>
                    <th *ngFor="let column of header" [style.min-width.px]="column.width">
                        {{column.name}}
                    </th>
                </tr>
            </table>
        </div>
        <cdk-virtual-scroll-viewport #scrollviewport class="viewport" itemSize="30" (scroll)="scrolling($event)">

            <table class="table" [style.min-width.px]="tableWidth">
                <ng-container *cdkVirtualFor="let pushpinPart of searchedPushpinParts; let i = index">
                    <tr (click)="openPushpin(pushpinPart.pushpin['id'], pushpinPart.id)">
                        <td *ngIf="tableColumns.get('number').visable" [style.min-width.px]="tableColumns.get('number').width" [style.max-width.px]="tableColumns.get('number').width">
                            <div>{{pushpinPart.pushpin.number}}</div>
                        </td>
                        <td *ngIf="tableColumns.get('updated_at').visable" [style.min-width.px]="tableColumns.get('updated_at').width" [style.max-width.px]="tableColumns.get('updated_at').width">
                            <div>{{pushpinPart.updated_at | date : 'd MMM yyyy, H:mm'}}</div>
                        </td>
                        <td *ngIf="tableColumns.get('executedDate').visable" [style.min-width.px]="tableColumns.get('executedDate').width" [style.max-width.px]="tableColumns.get('executedDate').width">
                            <div>{{pushpinPart['executedDate'] | date : 'd MMM yyyy, H:mm'}}</div>
                        </td>
                        <td *ngIf="tableColumns.get('zipcode').visable" [style.min-width.px]="tableColumns.get('zipcode').width" [style.max-width.px]="tableColumns.get('zipcode').width">
                            <div>{{pushpinPart.pushpin.zipcode}}</div>
                        </td>
                        <td *ngIf="tableColumns.get('place').visable" [style.min-width.px]="tableColumns.get('place').width" [style.max-width.px]="tableColumns.get('place').width">
                            <div>{{pushpinPart.pushpin.place}}</div>
                        </td>
                        <td *ngIf="tableColumns.get('street').visable" [style.min-width.px]="tableColumns.get('street').width" [style.max-width.px]="tableColumns.get('street').width">
                            <div>{{pushpinPart.pushpin.street}}</div>
                        </td>
                        <td *ngIf="tableColumns.get('housenumber').visable" [style.min-width.px]="tableColumns.get('housenumber').width" [style.max-width.px]="tableColumns.get('housenumber').width">
                            <div>{{pushpinPart.pushpin.housenumber}}</div>
                        </td>
                        <td *ngIf="tableColumns.get('price').visable" [style.min-width.px]="tableColumns.get('price').width" [style.max-width.px]="tableColumns.get('price').width">
                            <div>€ {{pushpinPartPricesMap[pushpinPart.id] | number:'1.2-2'}}</div>
                        </td>
                        <td *ngIf="tableColumns.get('inQuotation').visable" [style.min-width.px]="tableColumns.get('inQuotation').width" [style.max-width.px]="tableColumns.get('inQuotation').width">
                            <div>{{pushpinPart.inQuotation}}</div>
                        </td>
                        <td *ngIf="tableColumns.get('status').visable" [style.min-width.px]="tableColumns.get('status').width" [style.max-width.px]="tableColumns.get('status').width">
                            <div>{{pushpinPart.status.name}}</div>
                        </td>
                        <td *ngIf="tableColumns.get('decorationType').visable" [style.min-width.px]="tableColumns.get('decorationType').width" [style.max-width.px]="tableColumns.get('decorationType').width">
                            <div>{{pushpinPart.decoration.type}}</div>
                        </td>
                        <td *ngIf="tableColumns.get('decorationName').visable" [style.min-width.px]="tableColumns.get('decorationName').width" [style.max-width.px]="tableColumns.get('decorationName').width">
                            <div>{{pushpinPart.decoration.name}}</div>
                        </td>
                        <td *ngIf="tableColumns.get('material').visable" [style.min-width.px]="tableColumns.get('material').width" [style.max-width.px]="tableColumns.get('material').width">
                            <div>
                                <span [class.no-material]="pushpinPart.exclusive_material">
                                  {{pushpinPart.material.name}}
                                </span>
                                {{pushpinPart.primer ? ', Primer' : ''}}
                            </div>
                        </td>
                        <td *ngIf="tableColumns.get('paint_color').visable" [style.min-width.px]="tableColumns.get('paint_color').width" [style.max-width.px]="tableColumns.get('paint_color').width">
                            <div>{{pushpinPart.paint_color.name}}</div>
                        </td>
                        <td *ngIf="tableColumns.get('length').visable" [style.min-width.px]="tableColumns.get('length').width" [style.max-width.px]="tableColumns.get('length').width">
                            <div>{{pushpinPart.length | number:'1.0-0'}} m</div>
                        </td>
                        <td *ngIf="tableColumns.get('surface').visable" [style.min-width.px]="tableColumns.get('surface').width" [style.max-width.px]="tableColumns.get('surface').width">
                            <div>{{pushpinPart.surface | number:'1.0-2'}} m<sup>2</sup></div>
                        </td>
                        <td *ngIf="tableColumns.get('amount').visable" [style.min-width.px]="tableColumns.get('amount').width" [style.max-width.px]="tableColumns.get('amount').width">
                            <div>{{pushpinPart.amount}}</div>
                        </td>
                        <td *ngIf="tableColumns.get('crow').visable" [style.min-width.px]="tableColumns.get('crow').width" [style.max-width.px]="tableColumns.get('crow').width">
                            <div>{{pushpinPart.crow}}</div>
                        </td>
                        <td *ngIf="tableColumns.get('blast').visable" [style.min-width.px]="tableColumns.get('blast').width" [style.max-width.px]="tableColumns.get('blast').width">
                            <div>{{pushpinPart.blast ? 'Ja' : 'Nee'}}</div>
                        </td>
                        <td *ngIf="tableColumns.get('stake_out').visable" [style.min-width.px]="tableColumns.get('stake_out').width" [style.max-width.px]="tableColumns.get('stake_out').width">
                            <div>{{pushpinPart.stake_out ? 'Ja' : 'Nee'}}</div>
                        </td>
                        <td *ngIf="tableColumns.get('priority_figuration').visable" [style.min-width.px]="tableColumns.get('priority_figuration').width" [style.max-width.px]="tableColumns.get('priority_figuration').width">
                            <div>{{pushpinPart.decoration.priority_figuration ? 'Ja' : 'Nee'}}</div>
                        </td>
                        <td *ngIf="tableColumns.get('turbo_burn').visable" [style.min-width.px]="tableColumns.get('turbo_burn').width" [style.max-width.px]="tableColumns.get('turbo_burn').width">
                            <div>{{pushpinPart.turbo_burn ? 'Ja' : 'Nee'}}</div>
                        </td>
                        <td *ngIf="tableColumns.get('manual_burn').visable" [style.min-width.px]="tableColumns.get('manual_burn').width" [style.max-width.px]="tableColumns.get('manual_burn').width">
                            <div>{{pushpinPart.manual_burn ? 'Ja' : 'Nee'}}</div>
                        </td>
                        <td *ngIf="tableColumns.get('night').visable" [style.min-width.px]="tableColumns.get('night').width" [style.max-width.px]="tableColumns.get('night').width">
                            <div>{{pushpinPart.night ? 'Ja' : 'Nee'}}</div>
                        </td>
                        <td *ngIf="tableColumns.get('lat').visable" [style.min-width.px]="tableColumns.get('lat').width" [style.max-width.px]="tableColumns.get('lat').width">
                            <div>{{pushpinPart.pushpin.lat ? 'Ja' : 'Nee'}}</div>
                        </td>
                        <td *ngIf="tableColumns.get('lng').visable" [style.min-width.px]="tableColumns.get('lng').width" [style.max-width.px]="tableColumns.get('lng').width">{{pushpinPart.pushpin.lng ? 'Ja' : 'Nee'}}
                        </td>
                        <td *ngIf="tableColumns.get('description').visable" [style.min-width.px]="tableColumns.get('description').width" [style.max-width.px]="tableColumns.get('description').width">
                            <div>{{pushpinPart.description}}</div>
                        </td>
                        <td *ngIf="tableColumns.get('reference').visable" [style.min-width.px]="tableColumns.get('reference').width" [style.max-width.px]="tableColumns.get('reference').width">
                            <div>{{pushpinPart.pushpin?.reference}}</div>
                        </td>
                        <td *ngIf="tableColumns.get('customer_note').visable" [style.min-width.px]="tableColumns.get('customer_note').width" [style.max-width.px]="tableColumns.get('customer_note').width">
                            <div>{{pushpinPart.customer_note}}</div>
                        </td>
                    </tr>
                </ng-container>
            </table>
        </cdk-virtual-scroll-viewport>
    </div>

    <div *ngIf="showList && !tableView" class="viewport-container">
        <cdk-virtual-scroll-viewport #scrollviewport class="viewport" itemSize="207" *ngIf="!tableView">
            <div class="list">
                <div *ngIf="(searchedPushpinParts && searchedPushpinParts.length === 0) || !searchedPushpinParts">
                    Er zijn geen markeringsitems gevonden
                </div>
                <ng-container *cdkVirtualFor="let pushpinPart of searchedPushpinParts; let i = index">
                    <div (click)="openPushpin(pushpinPart.pushpin['id'], pushpinPart.id)"
                         class="list-item curpoint item{{i}}">
                        <div [class.filter-visible]="filterComponent.filterVisible"
                             [style.background-image]="pushpinPart.images[0].image|safeBgUrl"
                             class="image">
                            <div class="date">
                                {{pushpinPart.updated_at | date : 'd MMM yyyy, H:mm'}}
                            </div>
                            <div *ngIf="showName || pushpinPart.pushpin['consumer_created']" class="username">
                                {{pushpinPart.pushpin['user_name']}}
                            </div>
                        </div>
                        <div class="info">
                            <div class="main">
                                <div class="address">
                                    <div class="h4">
                                        {{pushpinPart.pushpin.street}} {{pushpinPart.pushpin.housenumber}}
                                    </div>
                                    <div class="place">
                                        {{pushpinPart.pushpin.place}}
                                    </div>
                                </div>
                                <div class="number">
                                    <div>
                                        <div *ngIf="pushpinPartPricesMap[pushpinPart.id] && showPrice" class="price">
                                            <span
                                                class="eu">€</span> {{pushpinPartPricesMap[pushpinPart.id] | number:'1.2-2'}}
                                        </div>
                                        <div class="nr">
                                            {{pushpinPart.pushpin.number}}
                                        </div>
                                        <div *ngIf="pushpinPart['loading']">
                                            <i class="fas fa-circle-notch fa-spin"></i>
                                        </div>
                                        <i (click)="setSelection($event, pushpinPart)"
                                           *ngIf="!pushpinPart['loading'] && inspection?.inspection_type_id === 1 && !inspection?.lock &&
                                           ((inspection?.inspection_status.selection_allowed && this.AuthorisationService.hasFeature('priceList'))
                                                || (inspection?.inspection_status_id === 2 && AuthorisationService.hasFeature('pushpinPartReleaseConsumer')))"
                                           [class.fa-check-square]="pushpinPart['checked'] && !pushpinPart['loading']"
                                           [class.fa-square]="!pushpinPart['checked']"
                                           class="far"></i>
                                    </div>
                                    <div class="reference">
                                        {{pushpinPart.pushpin?.reference}}
                                    </div>
                                </div>
                            </div>
                            <div class="part">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="d-flex flex-wrap row pt-1">
                                            <div class="col-2 title">Status</div>
                                            <div class="col-4 property">
                                                <span *ngIf="pushpinPart?.deleted_at">Verwijderd&nbsp;</span>
                                                <span [class.deleted]="pushpinPart?.deleted_at">{{pushpinPart.status.name}}</span>
                                                <ng-container *ngIf="pushpinPart.status_id === 9">
                                                    <small>{{pushpinPart.updated_at | date  : 'd MMM yyyy, H:mm'}}</small>
                                                </ng-container>
                                            </div>
                                            <ng-container *ngIf="pushpinPart.decoration.name">
                                                <div class="col-2 title">{{pushpinPart.decoration.type}}</div>
                                                <div class="col-4 property">{{pushpinPart.decoration.name}}</div>
                                            </ng-container>

                                            <div class="col-2 title">Materiaal</div>
                                            <div class="col-4 property">
                                                <span
                                                    [class.no-material]="pushpinPart.exclusive_material">
                                                    {{pushpinPart.material.name}}
                                                </span>
                                                {{pushpinPart.primer ? ', Primer' : ''}}
                                            </div>
                                            <div class="col-2 title">Kleur</div>
                                            <div class="col-4 property">{{pushpinPart.paint_color.name}}</div>

                                            <div class="col-2 title">Uitzetten</div>
                                            <div class="col-4 property">{{pushpinPart.stake_out ? 'Ja' : 'Nee'}}</div>
                                            <div class="col-2 title">Stralen</div>
                                            <div class="col-4 property">{{pushpinPart.blast ? 'Ja' : 'Nee'}}</div>

                                            <ng-container *ngIf="pushpinPart.decoration.type != 'Lengtemarkering'">
                                                <div class="col-2 title">Aantal</div>
                                                <div class="col-4 property">{{pushpinPart.amount}}</div>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="pushpinPart.decoration.type != 'Diversen vervangen' && !pushpinPart.decoration.specify_length">
                                                <div class="col-2 title">Oppervlakte</div>
                                                <div class="col-4 property">{{pushpinPart.surface | number:'1.0-2'}}
                                                    m<sup>2</sup>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="pushpinPart.decoration.specify_length">
                                                <div class="col-2 title">Lengte</div>
                                                <div class="col-4 property">{{pushpinPart.length| number:'1.0-0'}}
                                                    m
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="pushpinPart.overgrown">
                                                <div class="col-6 title">Overgroeide kanten</div>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="pushpinPart.status_id === 9 && pushpinPart['executedDate']">
                                                <div class="col-2 title">Uitgevoerd op</div>
                                                <div
                                                    class="col-4 property">{{pushpinPart['executedDate'] | date  : 'd MMM yyyy, H:mm'}}
                                                </div>
                                            </ng-container>
                                            <div *ngIf="pushpinPart.description && showName" class="col-12">
                                                <div class="title">Opmerking</div>
                                                <div class="partDescription">
                                                    {{pushpinPart.description}}
                                                </div>
                                            </div>
                                            <div *ngIf="pushpinPart.customer_note" class="col-12">
                                                <div *ngIf="showName" class="title">Opmerking Klant</div>
                                                <div *ngIf="!showName" class="title">Opmerking</div>
                                                <div>
                                                    {{pushpinPart.customer_note}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </cdk-virtual-scroll-viewport>
    </div>
</div>
<app-vvr-icon [dark]="false" [full]="false" [overlay]="true" [shadow]="true"></app-vvr-icon>
