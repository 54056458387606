import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {PushpinPart} from '../../classes/pushpinpart.class';
import {Status} from '../../classes/status.class';
import {StatusService} from '../../services/status/status.service';
import {Pushpin} from '../../classes/pushpin.class';
import {LocalStorage} from '../../storage.class';
import {MatDialog} from '@angular/material/dialog';
import {CROWIndicatorComponent} from '../crowindicator/crowindicator.component';
import {MaterialService} from '../../services/material/material.service';
import {Material} from '../../classes/material.class';
import {PushpinImage} from '../../classes/pushpin-image.class';
import {PushpinPartImageViewerDialogComponent} from '../pushpin-part-image-viewer-dialog/pushpin-part-image-viewer-dialog.component';
import {Decoration} from '../../classes/decoration.class';
import {DecorationsService} from '../../services/decorations/decorations.service';
import {CordovaService} from '../../cordova.service';
import {PaintColor} from '../../classes/paintcolor.class';
import {PaintColorService} from '../../services/paintColor/paint-color.service';
import {CustomerArea} from '../../classes/customerarea.class';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {PushpinService} from '../../services/pushpin/pushpin.service';
import {CodaltComponent} from '../../codalt.component';
import {Inspection} from '../../classes/inspection';

declare var cordova;

@Component({
    selector: 'app-pushpin-part-detail',
    templateUrl: './pushpin-part-detail.component.html',
    styleUrls: ['./pushpin-part-detail.component.scss']
})
export class PushpinPartDetailComponent extends CodaltComponent implements OnInit, OnChanges {

    userGroup;
    uniqueId: string;

    executionAllowed = false;
    isCordova = false;

    @Input()
    uploadDrag = false;

    @Input()
    pushpinPart: PushpinPart;

    @Input()
    customerArea: CustomerArea;

    @Input()
    inspection: Inspection;

    inspections: Inspection[];

    @Output()
    addForm = new EventEmitter<FormGroup>();
    statusList: Status[];
    materials: Material[];
    materialsAll: Material[];
    primers: Material[];
    paintColors: PaintColor[];
    decorations: Decoration[];
    form: FormGroup;
    initialStatus: Status = null;
    realizedCheckbox = false;
    freeForExecution = false;
    dateExecuted = null;
    @Input()
    public pushpin: Pushpin;
    @Input()
    private formMap: Map<PushpinPart, FormGroup>;
    @Output()
    private setBackbuttonAction = new EventEmitter<void>();
    @Output()
    private takePicture = new EventEmitter<void>();

    constructor(private statusService: StatusService,
                private materialService: MaterialService,
                private decorationsService: DecorationsService,
                private paintColorService: PaintColorService,
                private pushpinService: PushpinService,
                private dialog: MatDialog,
                private cordovaService: CordovaService,
                private confirmDialogService: ConfirmDialogService) {
        super();
        this.uniqueId = new Date().getTime() + ''.substr(-4);
    }

    ngOnInit() {
        this.inspections = this.customerArea.inspections
            .filter(i => i.inspection_type_id === this.pushpinPart.inspection.inspection_type_id &&
                (i.inspection_status.create_allowed || this.AuthorisationService.hasFeature('pushpinAlwaysAdd')));
        this.isCordova = typeof cordova !== 'undefined';
        this.userGroup = LocalStorage.getUser().group;


        this.materialService.getMaterials(true).then(materials => {
            this.materials = materials.filter(m => m.usable);
            this.materialsAll = materials;
            this.paintColorService.getList().then(paintColors => {
                this.paintColors = paintColors;
            });
        });

    }

    statusDisabled(status: Status) {
        // When consumer group and status not allowed for consumer, disable
        if (this.AuthorisationService.hasFeature('pushpinStatusFree')) {
            return false;
        }
        if (!this.AuthorisationService.hasFeature('pushpinPartStatusChangeAsNotVVRuserAllowd') && !status.vvruser_allowed) {
            return true;
        }
        if (this.AuthorisationService.hasFeature('pushpinPartCreateAsCustomerConcept') && !status.consumer_allowed) {
            return true;
        }

        if (this.initialStatus) {
            // WHen status is not current status and not allowed as next_status, disable
            if (status.id !== this.initialStatus.id &&
                this.initialStatus.next_status.indexOf(status.id) === -1) {
                return true;
            }
        }
        return false;
    }

    realizedCheckboxChange(event) {
        this.realizedCheckbox = event.checked;
        this.pushpinPart.status_id = (this.realizedCheckbox ? 4 : 3);
        this.pushpinPart.status = this.statusList.filter((status) => {
            return status.id === (this.realizedCheckbox ? 4 : 3);
        })[0];
        this.form.controls['status_id'].setValue(this.pushpinPart.status_id);
        if (this.realizedCheckbox) {
            this.confirmDialogService.confirm(
                'Geen foto gemaakt',
                `Er is geen foto gemaakt van de uitvoering, wilt u er nog een maken?`,
                'Ja',
                'Nee'
            ).then(() => {
                this.takePicture.emit();
            }, () => {

            });
        }
    }

    freeForExecutionCheckboxChange(event) {
        this.freeForExecution = event.checked;
        this.pushpinPart.status_id = (this.freeForExecution ? 3 : 2);
        this.pushpinPart.status = this.statusList.find((status) => {
            return status.id === (this.freeForExecution ? 3 : 2);
        });
        this.form.controls['status_id'].setValue(this.pushpinPart.status_id);
    }

    availablePrimers() {
        this.materialService.getMaterials(true).then(materials => {
            this.primers = materials.sort((a, b) => a.sort_order - b.sort_order)
                .filter(m => m.kind === 'Primer' &&
                    ['both', (this.pushpinPart.decoration.specific_price && this.pushpinPart.decoration.surface ? 'figuration' : 'material')].includes(m.suitable_kind) &&
                    m.suitable_materials.includes(this.pushpinPart.material_id)
                );
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.statusService.getStatuses(this.pushpinPart.inspection.inspection_type_id).then(statusList => {
            this.statusList = statusList;
            if (this.pushpinPart.status_id) {
                this.pushpinPart.status = this.statusList.filter(p => p.id === this.pushpinPart.status_id).pop();
                this.initialStatus = this.pushpinPart.status;
                // if status is 'gearchiveerd'
                if (this.pushpinPart.status_id === 9) {
                    this.pushpinService.getPushpinPartLog(this.pushpinPart.id).then(pushpinPartLog => {
                        const pushpinStatusLog = pushpinPartLog
                            .filter(log => log.field === 'status_id' && log.new_integer === 4)
                            .sort((a, b) => {
                                return (new Date(a.updated_at)).getTime() - (new Date(b.updated_at)).getTime();
                            });
                        if (pushpinStatusLog && pushpinStatusLog.length > 0) {
                            this.dateExecuted = pushpinStatusLog[pushpinStatusLog.length - 1].updated_at;
                        }
                    });
                }
            }
        });
        this.userGroup = LocalStorage.getUser().group;
        this.executionAllowed = this.pushpinPart.inspection.inspection_status.execution_allowed;
        this.realizedCheckbox = this.pushpinPart.status_id === 4;
        this.freeForExecution = this.pushpinPart.status_id === 3;
        if (!this.pushpinPart.status_id) {
            if (this.pushpinPart.inspection.inspection_type_id === 1) {
                if (this.AuthorisationService.hasFeature('pushpinPartCreateAsCustomerConcept')) {
                    this.pushpinPart.status_id = 8;
                } else {
                    this.pushpinPart.status_id = 1;
                }
            } else {
                this.pushpinPart.status_id = 11;
            }
            if (this.statusList) {
                this.pushpinPart.status = this.statusList.filter(p => p.id === this.pushpinPart.status_id).pop();
            }
        }
        if (!this.pushpinPart.paint_color_id) {

            this.pushpinPart.paint_color_id = 1;

            if (this.paintColors) {
                this.pushpinPart.paint_color = this.paintColors.filter(p => p.id === this.pushpinPart.paint_color_id).pop();
            }
        }
        this.initialStatus = this.pushpinPart.status;
        this.getDecorations();

        this.availablePrimers();

        if (this.formMap.has(this.pushpinPart)) {
            this.form = this.formMap.get(this.pushpinPart);
        } else {
            this.form = new FormGroup({
                inspection_id: new FormControl('', Validators.required),
                crow: new FormControl('', [Validators.maxLength(5)]),
                customer_note: new FormControl('', [Validators.maxLength(16500)]),
                primer: new FormControl(null, []),
                blast: new FormControl('', []),
                stake_out: new FormControl('', []),
                traffic_controllers: new FormControl('', []),
                amount: new FormControl('', [Validators.required]),
                material_id: new FormControl('', [Validators.required]),
                paint_color_id: new FormControl('', [Validators.required]),
                decoration_id: new FormControl('', [Validators.required, Validators.min(1)]),
                status_id: new FormControl('', [Validators.required, Validators.min(1)]),
                turbo_burn: new FormControl('', []),
                manual_burn: new FormControl('', []),
                night: new FormControl('', []),
                exclusive_material: new FormControl(false, []),
                overgrown: new FormControl(false, []),
                clean_surface: new FormControl(false, []),
            });
            this.form.get('inspection_id').valueChanges.subscribe(inspection_id => {
                if (this.inspections) {
                    Object.assign(this.pushpinPart.inspection, this.inspections.find(i => i.id === inspection_id));
                    this.disableSomeFieldsIfConsumerOrPreformedThermo();
                }
            });
            this.form.get('primer').valueChanges.subscribe(val => {
                if (val === true) {
                    const primer = this.materialsAll.sort((a, b) => a.sort_order - b.sort_order)
                        .find(m => m.kind === 'Primer' &&
                            ['both', (this.pushpinPart.decoration.specific_price && this.pushpinPart.decoration.surface ? 'figuration' : 'material')].includes(m.suitable_kind) &&
                            m.suitable_materials.includes(this.pushpinPart.material_id)
                        );
                    this.pushpinPart.primer = primer.id;
                    this.form.get('primer').setValue(this.pushpinPart.primer, {emitEvent: false});
                }
            });
            if (this.pushpinPart.material_id === 5) {
                this.form.addControl('description', new FormControl('', [
                    Validators.maxLength(16500), Validators.required]));
            } else {
                this.form.addControl('description', new FormControl('', [
                    Validators.maxLength(16500)]));
            }
            if (this.pushpinPart.decoration.surface === null && this.pushpinPart.decoration.type &&
                this.pushpinPart.decoration.type !== 'Diversen vervangen' &&
                this.pushpinPart.decoration.decoration_group !== 'Voorgevormd') {
                this.form.addControl('surface', new FormControl('', [
                    Validators.required, Validators.min(0.01), Validators.max(999999.99)]));
            } else if (this.pushpinPart.decoration.specify_length) {
                if (this.pushpin.draft) {
                    this.form.addControl('length', new FormControl('', [
                        Validators.min(0.01), Validators.max(999999.99)]));
                } else {
                    this.form.addControl('length', new FormControl('', [
                        Validators.required, Validators.min(0.01), Validators.max(999999.99)]));
                }
            }
        }

        Object.entries(this.form.controls).forEach(control => {
            this.form.controls[control[0]].setValue(this.pushpinPart[control[0]]);
        });

        if (this.pushpinPart['invalid']) {
            for (const name of Object.keys(this.form.controls)) {
                this.form.controls[name].markAsTouched();
            }
        }

        this.addForm.emit(this.form);

        this.disableSomeFieldsIfConsumerOrPreformedThermo();
    }

    crowDialog(event) {
        event.stopPropagation();
        this.dialog.open(CROWIndicatorComponent, {
            panelClass: 'crow-dialog',
            data: this.pushpinPart
        });
    }

    decorationChange(event) {
        if (typeof event.value === 'number' && this.decorations) {
            this.pushpinPart.decoration = this.decorations.filter((decoration) => {
                return decoration.id === event.value;
            })[0];
        }
    }

    materialChange(event) {
        if (typeof event.value === 'number' && this.materials) {
            this.pushpinPart.material = this.materials.find((material) => {
                return material.id === event.value;
            });
            this.pushpinPart.material_id = event.value;
        }
        if (this.pushpinPart.material.primer) {
            const primer = this.materialsAll.sort((a, b) => a.sort_order - b.sort_order)
                .find(m => m.kind === 'Primer' &&
                    ['both', (this.pushpinPart.decoration.specific_price && this.pushpinPart.decoration.surface ? 'figuration' : 'material')].includes(m.suitable_kind) &&
                    m.suitable_materials.includes(this.pushpinPart.material_id)
                );
            this.pushpinPart.primer = primer.id;
            this.form.get('primer').setValue(this.pushpinPart.primer);
        } else {
            this.pushpinPart.primer = null;
            this.form.get('primer').setValue(false);
        }
        this.availablePrimers();
    }

    statusChange(event) {
        if (typeof event.value === 'number' && this.statusList) {
            this.pushpinPart.status = this.statusList.filter((status) => {
                return status.id === event.value;
            })[0];
        }
    }

    openImage(images: PushpinImage[], clickedImage: PushpinImage) {
        const dialogRef = this.dialog.open(PushpinPartImageViewerDialogComponent, {
            panelClass: 'image-viewer-dialog',
            data: {images: images, viewIndex: images.indexOf(clickedImage)}
        });
        this.cordovaService.setBackbuttonAction(() => {
            dialogRef.close();
        });
        const subs = dialogRef.afterClosed().subscribe((pushpinPart: PushpinPart) => {
            this.setBackbuttonAction.emit();
            subs.unsubscribe();
        });
    }

    multiplyLength(multiplyBy) {
        const current = this.form.controls['length'].value;
        const allowedCurrent = [
            0,
            null,
            this.pushpin.snappedpoints.totalLength,
            this.pushpin.snappedpoints.totalLength * 2,
            this.pushpin.snappedpoints.totalLength * 3,
            this.pushpin.snappedpoints.totalLength * 4
        ];
        if (allowedCurrent.indexOf(current) === -1) {
            this.confirmDialogService.confirm(
                'Lengte is aangepast',
                `De ingevoerde lengte (${current}m) komt niet overeen met de middels GPS gemeten lengte  (${this.pushpin.snappedpoints.totalLength}m).
                Wil je de lengte echt overschrijven?`,
                'ja', 'nee').then(() => {
                this.doMultiplyLength(multiplyBy);
            }, () => {
            });
        } else {
            this.doMultiplyLength(multiplyBy);
        }

    }

    disableSomeFieldsIfConsumerOrPreformedThermo() {
        setTimeout(() => {
            if ((this.pushpinPart.id &&
                    LocalStorage.getUser().id !== this.pushpinPart.user_id &&
                    !this.AuthorisationService.hasAnyFeature(['allCustomerAreas', 'customerAreaWithWork']))
                || this.pushpinPart.deleted_at || this.pushpinPart.inspection?.lock
            ) {
                this.form.controls['description'].disable();
                this.form.controls['status_id'].disable();
                this.form.controls['crow'].disable();
                this.form.controls['primer'].disable();
                this.form.controls['blast'].disable();
                this.form.controls['stake_out'].disable();
                this.form.controls['night'].disable();
                this.form.controls['manual_burn'].disable();
                this.form.controls['turbo_burn'].disable();
                this.form.controls['exclusive_material'].disable();
                this.form.controls['overgrown'].disable();
                this.form.controls['clean_surface'].disable();
                this.form.controls['traffic_controllers'].disable();
                this.form.controls['amount'].disable();
                this.form.controls['material_id'].disable();
                this.form.controls['decoration_id'].disable();
                this.form.controls['paint_color_id'].disable();
                if (typeof this.form.controls['length'] !== 'undefined') {
                    this.form.controls['length'].disable();
                }
                if (typeof this.form.controls['surface'] !== 'undefined') {
                    this.form.controls['surface'].disable();
                }
            } else if (this.pushpinPart.material_id === 5) {
                this.form.controls['material_id'].disable();
            } else {
                this.form.controls['description'].enable();
                this.form.controls['status_id'].enable();
                this.form.controls['crow'].enable();
                this.form.controls['primer'].enable();
                this.form.controls['blast'].enable();
                this.form.controls['stake_out'].enable();
                this.form.controls['night'].enable();
                this.form.controls['manual_burn'].enable();
                this.form.controls['turbo_burn'].enable();
                this.form.controls['exclusive_material'].enable();
                this.form.controls['overgrown'].enable();
                this.form.controls['clean_surface'].enable();
                this.form.controls['traffic_controllers'].enable();
                this.form.controls['amount'].enable();
                this.form.controls['material_id'].enable();
                this.form.controls['decoration_id'].enable();
                this.form.controls['paint_color_id'].enable();
                if (typeof this.form.controls['length'] !== 'undefined') {
                    this.form.controls['length'].enable();
                }
                if (typeof this.form.controls['surface'] !== 'undefined') {
                    this.form.controls['surface'].enable();
                }
            }
            if (!this.AuthorisationService.hasFeature('pushpinDescription')) {
                this.form.controls['description'].disable();
            }
            if (!this.AuthorisationService.hasFeature('pushpinConsumerDescription')) {
                this.form.controls['customer_note'].disable();
            }
            if (this.pushpinPart.status_id === 9) {
                for (const name of Object.keys(this.form.controls)) {
                    this.form.controls[name].disable();
                }
            }
        }, 0);
    }

    private doMultiplyLength(multiplyBy) {
        if (multiplyBy) {
            this.form.controls['length'].setValue(Math.round(this.pushpin.snappedpoints.totalLength * multiplyBy));
        } else {
            this.form.controls['length'].setValue(Math.round(this.pushpin.snappedpoints.totalLength));
        }

    }

    private getDecorations() {
        this.decorationsService.getList().then(decorations => {
            this.decorations = decorations.filter(decoration => {
                return decoration.type === this.pushpinPart.decoration.type
                    && decoration.decoration_group === this.pushpinPart.decoration.decoration_group;
            }).sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            }).sort((a, b) => {
                return a.order - b.order;
            });
        });
    }

}
