import {User} from './user.class';
import {RepairComment} from './repaircomment.class';

export class Repair {
    id: number;
    type_id: number;
    machine_number: string;
    user_id: number;
    status: string;
    description: string;
    files: Array<any>;
    created_at: Date;
    comments: RepairComment[];
    user: User;
}
