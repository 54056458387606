import {Injectable} from '@angular/core';
import {Status} from '../../classes/status.class';
import {ApiResponse, ApiService} from '../api/api.service';
import {PushpinPart} from '../../classes/pushpinpart.class';
import {share} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class StatusService {

    private _statusList: Status[] = [];
    private getListCall: Observable<ApiResponse<Status[]>>;

    constructor(private apiService: ApiService) {
    }

    getStatusCounts(customer_area_id: number, inspection_id?: number) {
        let params = {customer_area_id} as any;
        if (inspection_id) {
            params = {...params, inspection_id};
        }
        return this.apiService.getCall$<{ id: number, count: number }[]>('statuses/counts', params);
    }

    public getStatuses(inspectionTypeId = 1): Promise<Status[]> {
        return new Promise((resolve, reject) => {
            if (this._statusList && this._statusList.length > 0) {
                resolve(this._statusList.filter(s => s.inspection_type_id === inspectionTypeId || inspectionTypeId === 0));
            } else {
                if (!this.getListCall) {
                    this.getListCall = this.apiService.getCall$<Status[]>('statuses').pipe(share());
                }
                this.getListCall.subscribe((statuses: ApiResponse<Status[]>) => {
                    this._statusList = statuses.data;
                    this.getListCall = undefined;
                    resolve(this._statusList.filter(s => s.inspection_type_id === inspectionTypeId || inspectionTypeId === 0));
                }, err => {
                    reject(err);
                });
            }
        });
    }

    public getKeyValMap(): Promise<object> {
        return new Promise((resolve, reject) => {
            this.getStatuses(0).then((materials) => {
                resolve(this.toKeyVal(materials));
            }, () => reject());
        });
    }

    public getHeaviestStatuses(parts: PushpinPart[]): Status[] {
        if (!parts) {
            return [];
        }

        const statusIds = parts.map(p => p.status_id);

        const statuses = this._statusList
            .filter(s => statusIds.indexOf(s.id) !== -1)
            .sort((a, b) => {
                return b.weight - a.weight;
            });

        return statuses.slice(0, 2);
    }

    private toKeyVal(arr: object[]) {
        const keyValMap = {};
        arr.forEach(item => {
            keyValMap[item['id']] = item;
        });
        return keyValMap;
    }
}

