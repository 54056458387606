import {Component, OnDestroy, OnInit} from '@angular/core';
import {User} from '../classes/user.class';
import {LocalStorage} from '../storage.class';
import {Router} from '@angular/router';
import {CustomerAreaService} from '../services/consumer-area/customer-area.service';
import {CustomerArea} from '../classes/customerarea.class';
import {CordovaService} from '../cordova.service';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {HttpClient} from '@angular/common/http';
import {CodaltComponent} from '../codalt.component';
import {FirebaseX} from '@ionic-native/firebase-x/ngx';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent extends CodaltComponent implements OnInit, OnDestroy {
    cordovaVersion = '';

    management = [
        'declarationManagement',
        'priceChangeLog',
        'pricesSave',
        'customerAreaSave',
        'extraCostSave',
        'decorationSave',
        'userGet',
        'managementDashboard',
        'saveMoldMaterial',
        'saveSmallMaterial'
    ];

    user: User;
    customerAreas: CustomerArea[];

    constructor(private router: Router,
                private customerAreaService: CustomerAreaService,
                private confirmDialogService: ConfirmDialogService,
                private firebase: FirebaseX,
                private http: HttpClient,
                private cordovaService: CordovaService) {
        super();
    }

    ngOnInit() {
        this.user = LocalStorage.getUser();
        if (window['cordova']) {
            this.customerAreaService.getList().then(customerAreas => {
                if (this.AuthorisationService.hasFeature('firebaseSubs')) {
                    customerAreas.forEach(customerArea => {
                        this.firebase.subscribe('ADMIN_CONSUMER_NOTIF_' + customerArea.id);
                    });
                } else {
                    this.firebase.unregister().then(() => {
                        this.firebase.subscribe('alldevices');
                    });
                }
            });

            if (this.AuthorisationService.hasFeature('firebaseSubsAdmin')) {
                this.firebase.subscribe('VVRADMIN_NOTIF');
            }
            if (this.AuthorisationService.hasFeature('firebaseSubsArticlesVVR')) {
                this.firebase.subscribe('ARTICLE_NOTIF_VVR');
            }
            if (this.AuthorisationService.hasFeature('firebaseSubsArticlesConsumer')) {
                this.firebase.subscribe('ARTICLE_NOTIF_CONSUMER');
            }
            if (this.AuthorisationService.hasFeature('firebaseSubsArticles')) {
                this.firebase.subscribe('ARTICLE_NOTIF_EVERYONE');
            }
            if (this.AuthorisationService.hasFeature('repairManagement')) {
                this.firebase.subscribe('REPAIR_NOTIF');
            }
            this.cordovaService.setBackbuttonExitAction();

            this.cordovaService.getAppVersion().then((version) => {
                this.cordovaVersion = version;
                LocalStorage.appVersion = version;
            });
        } else {
            this.http.get('assets/version.json').subscribe(versionInfo => {
                this.cordovaVersion = versionInfo['version'];
                LocalStorage.appVersion = versionInfo['version'];
            }, () => {

            });
        }
    }

    logout() {
        this.confirmDialogService.confirm(
            'Uitloggen',
            `Wil je echt uitloggen?`,
            'Ja', 'Nee').then(() => {
            LocalStorage.logoutUser();
            this.firebase.unregister().then(() => {
                this.firebase.subscribe('alldevices');
            });
            this.router.navigate([`/login`]);
        }, () => {

        });

    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
